import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getProductGroups } from '_services/products';
import { useAtom } from 'jotai';
import { userAtom } from '../../../_atoms/user';
import AddRawMaterialDialog from 'components/Product/AddRawMaterialDialog';
import ChooseRawMaterialDialog from 'components/Product/ChooseRawMaterialDialog';
import AddHalfProductDialog from 'components/Product/AddHalfProductDialog';
import ChooseHalfProductDialog from 'components/Product/ChooseHalfProductDialog';
import { addProduct, addProductWp } from '../../../_services/products.js'
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import ImageUpload from 'components/CustomUpload/ImageUpload.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ImageUploader from 'react-images-upload';
import {
  successAlert,
  warningAlert,
} from 'components/SweetAlerts/Alerts';
import { Typography } from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';
import AddProductGroupDialog from 'components/Product/AddProductGroupDialog.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const AddFullProduct = () => {
  const history = useHistory();
  const classes = useStyles();
  const [user] = useAtom(userAtom);
  const [swal, setSwal] = useState(null);
  const [name, setName] = useState('');
  const [alert, setAlert] = useState('');
  const [code, setCode] = useState('');
  const [productGroups, setProductGroups] = useState([]);
  const [productGroup, setProductGroup] = useState('');
  const [ dynamicFields, setDynamicFields ] = useState([])

  const [ file, setFile ] = useState('')
  const [ fileToCrop, setFileToCrop ] = useState('')

  const [showAddRawDialog, setShowAddRawDialog] = useState(false);
  const [showChooseRawDialog, setShowChooseRawDialog] = useState(false);
  const [showAddHalfDialog, setShowAddHalfDialog] = useState(false);
  const [showChooseHalfDialog, setShowChooseHalfDialog] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  const [ PGDialog, SetPGDialog ] = useState(false)

  const PGDialogToggle = () => {
    SetPGDialog(!PGDialog)
  }
  const { token  } = user.user

  const handleSubmit =  e => {
    e.preventDefault();
    if(file === ''){
        const data = {
            name, code, productGroup, dynamicFields, isFullProduct : true 
        }
        addProduct(token,data)
        .then(res => {
            console.log(res.data)
        })
        .catch(err => {
            console.log(err)
        })
        // Swal.fire({
        //     type: 'error',
        //     title: 'Lütfen dosya seçiniz.'
        // })
    }else{
        const fd = new FormData();
        fd.append('productPhoto', file, file.name)
        fd.append('name', name)
        fd.append('code', code)
        fd.append('productGroup', productGroup)
        fd.append('isFullProduct', 'true')
        fd.append('dynamicFields', JSON.stringify(dynamicFields))
        submitOperation(fd)    
    }
  }

  const submitOperation = data => {
    addProductWp(token,data)
    .then(res => {
        console.log(1,res.data)
    })
    .catch(err => {
        console.log(err)
    })
  }

  useEffect(() => {
    fetchData()
  }, [user])

  const classes2 = useStyles2();

  const fetchData = () => {
      getProductGroups(user.user.token)
      .then((res) => {
      if (res.data && res.data.code && res.data.code === 200) {
          setProductGroups(res.data.data);
      }
      })
      .catch((err) => console.error(err));
  }

  const onDrop = (picture) => {
    if( picture.length > 0){
        const currentFile = picture[0]
        const myFileItemReader = new FileReader()
        myFileItemReader.addEventListener("load", ()=>{
            const myResult = myFileItemReader.result
            setFileToCrop(myResult)
            setFile(picture[0])
        }, false)
        myFileItemReader.readAsDataURL(currentFile)
    }else{
        setFileToCrop('')
    }
  }

  const handleProductGroup = id => {
    const index = productGroups.map( g => g._id).indexOf(id)
    setProductGroup(id)
    if(productGroups[index] && productGroups[index].dynamicFields ){
        setDynamicFields(productGroups[index].dynamicFields)
    }
  }

  const handleDynamicField = ( value, id ) => {
    const index = dynamicFields.map( f => f._id).indexOf(id)
    setDynamicFields([
        ...dynamicFields.slice(0, index), 
        { ...dynamicFields[index], value},
        ...dynamicFields.slice(index+1)
    ])
  }

  console.log(dynamicFields)

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              justIcon
              color='transparent'
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </Button>
            <h4 className={classes.cardTitle}>Add Product</h4>
          </div>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer justify='flex-end'>
          {alert !== '' && (
            <GridItem xs={12}>
              <SnackbarContent message={alert} color='danger' />
            </GridItem>
          )}
          <GridItem xs={12} md={6}>
            <GridContainer justify='center'>
                <GridItem xs={12} md={4}>
                    <ImageUploader
                        withIcon={true}
                        buttonText='Fotoğraf Seçiniz'
                        onChange={onDrop}
                        imgExtension={['.jpg', '.jpeg', '.png']}
                        maxFileSize={5242880}
                        singleImage = {true}
                        fileTypeError = 'Desteklenmeyen dosya formatı!!'
                        fileSizeError = 'Dosya çok büyük!'
                        label = 'Maks 5mb, jpeg veya png.'
                        withPreview = {true}
                    />
                </GridItem>               
                { fileToCrop === ''
                    ?   null
                    :   <GridItem xs={12} md={8}>   
                            <img src={fileToCrop} style={{width: '100%'}} alt="Profil Fotoğrafı" />
                        </GridItem>
                }    
            </GridContainer>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridContainer justify='flex-start' alignItems='center'>
                <GridItem xs={12} md={6}>
                    <CustomInput
                    labelText='Product Name'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        name: 'name',
                        value: name,
                        onChange: (e) => {
                        setName(e.target.value);
                        },
                    }}
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <CustomInput
                    labelText='Code'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        name: 'code',
                        value: code,
                        onChange: (e) => {
                        setCode(e.target.value);
                        },
                    }}
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{ marginTop: '11px' }}
                    >
                    <InputLabel
                        htmlFor='simple-select'
                        // className={classes.selectLabel}
                    >
                        Choose Product Group
                    </InputLabel>
                    <Select
                        MenuProps={{
                        className: classes.selectMenu,
                        }}
                        classes={{
                        select: classes.select,
                        }}
                        value={productGroup}
                        onChange={(e) => handleProductGroup(e.target.value)}
                        inputProps={{
                        name: 'simpleSelect',
                        id: 'simple-select',
                        }}
                    >
                        {productGroups.map((grp) => {
                        return (
                            <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value={grp._id}
                            key={grp._id}
                            >
                            {grp.name}
                            </MenuItem>
                        );
                        })}
                    </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <Button color='success' block onClick={PGDialogToggle}>
                        New Product Group
                    </Button>
                </GridItem>
                { dynamicFields.length > 0 &&
                    <GridItem xs={12}>
                        <h4>Dynamic Fields</h4>
                    </GridItem>
                }
                { dynamicFields.map( f => {
                    return(
                        <GridItem xs={12} md={6} key={f._id}>
                            <CustomInput
                            labelText={f.name + ' (' +f.unit+')'}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                type : f.isNumber ? 'number' : "text",
                                name: 'code',
                                value: f.value || '',
                                onChange: (e) => {
                                    handleDynamicField(e.target.value, f._id);
                                },
                            }}
                            />
                        </GridItem>
                    )
                })}
            </GridContainer>
          </GridItem>
          {swal}
          <GridItem xs={3}>
            <Button color='success' block onClick={handleSubmit}>
                Add Product
            </Button>
        </GridItem>
        </GridContainer>
      </CardBody>
      <AddProductGroupDialog
        open={PGDialog}
        toggle={PGDialogToggle}
        token = {user.user.token}
      />
    </Card>
  );
};

export default AddFullProduct;
