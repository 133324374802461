import React, { useState, useEffect } from 'react';
// nodejs library to set properties for components
import { update } from '../../_services/user'
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { useHistory } from 'react-router-dom';

import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
import TranslateIcon from '@material-ui/icons/Translate';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js';

const useStyles = makeStyles(styles);
export default function AdminNavbar(props) {
  const classes = useStyles();
  const [user, setUser] = useAtom(userAtom);
  const { layoutPermissions } = user.user;
  const history = useHistory();
  const [btnText, setBtnText] = useState('');
  const { color, rtlActive, brandText } = props;

  const languages = ['EN', 'TR'];

  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });

  useEffect(() => {
    setBtnText(history.location.pathname.split('/')[1]);
  }, [history.location]);

  const handleLanguageChange = (ln) => {
    const lang = ln.toLowerCase()
    const token = user.user.token
    const fulldata = { _id : user.user._id, data : { language : lang } }
    update(fulldata,token)
    .then( res => {
      if(res.data && res.data.code === 200){
        setUser({ user: res.data.data, loggedIn: true })
      }else{

      }
    })
    .catch( err => null)
  };

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation='css'>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color='white'
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color='white'
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <Hidden smDown implementation='css'>
          <div style={{ marginLeft: 10 }}>
            <CustomDropdown
              buttonText={btnText}
              hoverColor='info'
              buttonProps={{
                round: true,
                block: true,
                color: 'info',
              }}
              dropPlacement='bottom'
              dropdownList={[...layoutPermissions]}
              onClick={(perm) => {
                setBtnText(perm);
                history.push(`/${perm}`);
              }}
            />
          </div>
        </Hidden>
        <Hidden smDown implementation='css'>
          <div style={{ marginLeft: '30px' }}>
            <CustomDropdown
              justIcon
              buttonIcon={TranslateIcon}
              hoverColor='info'
              buttonProps={{
                round: true,
                block: true,
                color: 'info',
              }}
              dropPlacement='bottom'
              dropdownList={[...languages]}
              onClick={handleLanguageChange}
            />
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href='#' className={classes.title} color='transparent'>
            {brandText}
          </Button>
        </div>
        <Hidden smDown implementation='css'>
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation='css'>
          <Button
            className={classes.appResponsive}
            color='transparent'
            justIcon
            aria-label='open drawer'
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
