import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';

const AddRawMaterialDialog = (props) => {
  const { open, toggle } = props;
  return (
    <Dialog open={open} onClose={toggle} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Kişi Ekle</DialogTitle>
      <DialogContent>
        <h1>add raw material dialog</h1>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default AddRawMaterialDialog;
