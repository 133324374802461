const units = [
    { 
        unit : 'cm',
        tr : 'cm',
        en : 'cm'
    },
    { 
        unit : 'm2',
        tr : 'metrekare',
        en : 'meter square'
    },
]

export const unitsLP = (ln) => {
    return units.map((s) => {
      return { value: s.unit, name: s[ln] };
    });
};