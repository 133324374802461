import Calendar from 'views/Calendar/Calendar.js';

// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
import GridOn from '@material-ui/icons/GridOn';
import Image from '@material-ui/icons/Image';
import Place from '@material-ui/icons/Place';
import Timeline from '@material-ui/icons/Timeline';
import WidgetsIcon from '@material-ui/icons/Widgets';

import RawMaterials from 'views/RawMaterials/RawMaterials';
import AddRawMaterial from 'views/RawMaterials/AddRawMaterial';
import AddDepartment from 'views/Departments/AddDepartment';
import Departments from 'views/Departments/Departments';
import Operations from 'views/Operations/Operations';
import AddOperation from 'views/Operations/AddOperation';
import Products from 'views/Products/Products';
import AddProduct from 'views/Products/AddProduct';
import HalfProducts from 'views/Products/HalfProducts';

var erpRoutes = [
  {
    path: '/addrawmaterial',
    component: AddRawMaterial,
    invisible: true,
    layout: '/erp',
  },
  {
    path: '/addDepartment',
    component: AddDepartment,
    invisible: true,
    layout: '/erp',
  },
  {
    path: '/addoperation',
    component: AddOperation,
    invisible: true,
    layout: '/erp',
  },
  {
    path: '/erpdashboard',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/erp',
  },
  {
    collapse: true,
    path: '/components',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/erp',
    state: 'bilesenlerCollapse',
    views: [
      {
        path: '/raw-materials',
        mini: 'RM',
        component: RawMaterials,
        layout: '/erp',
      },
      {
        path: '/departments',
        mini: 'DP',
        component: Departments,
        layout: '/erp',
      },
      {
        path: '/operations',
        mini: 'OP',
        component: Operations,
        layout: '/erp',
      },
    ],
  },
  {
    path: '/addproduct',
    component: AddProduct,
    invisible: true,
    layout: '/erp',
  },
  {
    collapse: true,
    path: '/product',
    icon: DashboardIcon,
    component: Products,
    layout: '/erp',
    state: 'urunlerCollapse',
    views: [
      {
        path: '/products',
        mini: 'Ü',
        component: Products,
        layout: '/erp',
      },
      {
        path: '/half-products',
        mini: 'YM',
        component: HalfProducts,
        layout: '/erp',
      },
      {
        path: '/product-groups',
        mini: 'ÜG',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/product-trees',
        mini: 'ÜA',
        component: Calendar,
        layout: '/erp',
      },
    ],
  },
  {
    collapse: true,
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Calendar,
    path: '/purchase',
    layout: '/erp',
    state: 'satinAlmaCollapse',
    views: [
      {
        path: '/purchase-processes',
        mini: 'IS',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/purchasing',
        mini: 'SP',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/reports',
        mini: 'RP',
        component: Calendar,
        layout: '/erp',
      },
    ],
  },
  {
    collapse: true,
    path: '/order',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/erp',
    state: 'siparislerCollapse',
    views: [
      {
        path: '/order-processes',
        mini: 'IS',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/orders',
        mini: 'SP',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/order-reports',
        mini: 'RP',
        component: Calendar,
        layout: '/erp',
      },
    ],
  },
  {
    collapse: true,
    path: '/production',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/erp',
    state: 'uretimCollapse',
    views: [
      {
        path: '/production-orders',
        mini: 'İE',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/production-order-archive',
        mini: 'İEA',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/plan-production',
        mini: 'ÜP',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/planned-production',
        mini: 'PÜ',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/production-reports',
        mini: 'ÜR',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/production-archive',
        mini: 'ÜA',
        component: Calendar,
        layout: '/erp',
      },
    ],
  },
  {
    collapse: true,
    path: '/stock',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/erp',
    state: 'stokCollapse',
    views: [
      {
        path: '/waybills',
        mini: 'İ',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/waybills-archive',
        mini: 'İA',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/raw-material-shipment',
        mini: 'HS',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/raw-material-stock',
        mini: 'HS',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/product-stock',
        mini: 'ÜS',
        component: Calendar,
        layout: '/erp',
      },
      {
        path: '/half-product-stock',
        mini: 'YS',
        component: Calendar,
        layout: '/erp',
      },
    ],
  },
];
export default erpRoutes;
