import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import { updateCompany } from '_services/company';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const GeneralInfo = (props) => {
  const { company, hideAlert, successAlert, warningAlert, setAlert } = props;
  const [ editable, setEditable] = useState(true);
  const [name, setName] = useState(''); // required
  const [code, setCode] = useState(''); // required
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [vergiDairesi, setVergiDairesi] = useState('');
  const [vkn, setVkn] = useState('');
  const [ticSicil, setTicSicil] = useState('');
  const [nickName, setNickName] = useState('');
  const [user] = useAtom(userAtom);
  const classes = useStyles();
  const classes2 = useStyles2();


  useEffect(() => {
    if (company._id) {
      setName(company.name);
      setCode(company.code);
      setAddress(company.address);
      setWebsite(company.website);
      setEmail(company.email);
      setPhone(company.phone);
      setFax(company.fax);
      setVergiDairesi(company.vergiDairesi);
      setVkn(company.vkn);
      setTicSicil(company.ticSicil);
      setNickName(company.nickName);
    }
  }, [company]);

  const handleChange = () => {
    const data = {
      name: name,
      code: code,
      address: address,
      website: website,
      email: email,
      phone: phone,
      fax: fax,
      vergiDairesi: vergiDairesi,
      vkn: vkn,
      ticSicil: ticSicil,
      nickName: nickName,
      _id: company._id,
    };

    if (name === '') {
      warningAlert(setAlert, hideAlert, classes2, 'Company name is required!');
    } else if (code === '') {
      warningAlert(setAlert, hideAlert, classes2, 'Company code is required!');
    } else {
      updateCompany(data, user.user.token)
        .then((res) => {
          if (res.data && res.data.code === 200) {
            console.log(res);
            successAlert(setAlert, hideAlert, classes2, '');
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setEditable(!editable);
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <GridContainer justify='center'>
        <GridItem xs={11}>
          <GridContainer justify='flex-end'>
            <GridItem xs={2}>
              <Button block justIcon simple size='lg' color='success' onClick={() => setEditable(!editable)}>
                <EditIcon />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Title'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: name,
              disabled: editable,
              onChange: (e) => {
                setName(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <CustomInput
            labelText='Company Short Name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'name',
              value: nickName,
              disabled: editable,
              onChange: (e) => {
                setNickName(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Code'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: code,
              disabled: editable,
              onChange: (e) => {
                setCode(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <CustomInput
            labelText='Email'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: email,
              disabled: editable,
              onChange: (e) => {
                setEmail(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Web Page'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: website,
              disabled: editable,
              onChange: (e) => {
                setWebsite(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <CustomInput
            labelText='Phone'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: phone,
              disabled: editable,
              onChange: (e) => {
                setPhone(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Fax'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: fax,
              disabled: editable,
              onChange: (e) => {
                setFax(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <CustomInput
            labelText='Tax Office'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: vergiDairesi,
              disabled: editable,
              onChange: (e) => {
                setVergiDairesi(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Tax ID'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: vkn,
              disabled: editable,
              onChange: (e) => {
                setVkn(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={5}>
          <CustomInput
            labelText='Trade Registration Number'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: ticSicil,
              disabled: editable,
              onChange: (e) => {
                setTicSicil(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={11}>
          <CustomInput
            labelText='Address'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: address,
              disabled: editable,
              onChange: (e) => {
                setAddress(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={11}>
          <GridContainer justify='flex-end'>
            <GridItem xs={2}>
              {!editable && <Button color='success' block onClick={handleChange}>Save</Button>}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default GeneralInfo;
