import React from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Typography from '@material-ui/core/Typography';
import Button from 'components/CustomButtons/Button';

const Lead = (props) => {
  const { name, _id } = props.lead;
  const { handleClick, color } = props;
  return (
    <GridItem style={{ padding: 0, margin: 0 }}>
      <Button
        color={color}
        onClick={() => {
          handleClick(_id, name);
        }}
        size='sm'
        block
      >
        <Typography variant='body1'>{name}</Typography>
      </Button>
    </GridItem>
  );
};

export default Lead;
