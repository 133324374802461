import React, { useRef, useState } from 'react'
import GridContainer from 'components/Grid/GridContainer.js';
import Typography from '@material-ui/core/Typography';
import GridItem from 'components/Grid/GridItem.js';
import Lead from 'components/Yetkiler/Lead.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js'
import Xarrow from "react-xarrows";
import Draggable from "react-draggable";
import {useDropzone} from 'react-dropzone';
import 'assets/css/pozisyonlarHierarchy.css'

const Hierarchy = props => {
    const { getRootProps } = useDropzone();
    const [, setRender] = useState({});
    const { uppers, lowers, element, action } = props
    const positionRef = useRef(null);
    const forceRerender = () => setRender({});
    return(
        <GridContainer>
            <GridItem xs={12}>
                <GridContainer justify='center'>
                    {uppers.map((manager) => {
                    return (
                        <Draggable 
                        onStop={forceRerender} onDrag={forceRerender} 
                        position={null} key={manager._id}
                        >
                            <div id={manager._id}>
                            <Lead lead={manager}  handleClick={action} color='info' />
                            </div>
                        </Draggable>
                        )
                    })}
                </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                <GridContainer justify='center' alignItems='center' style={{height : 350}}>
                    <GridItem>
                    <div ref={positionRef} {...getRootProps} className='pozisyonlarGrid' key={element.name}>
                    <Card variant='outlined' style={{borderRadius : 15, width: 250, height: 100}}>
                        <CardBody style={{ display : "flex", justifyContent : "center", alignItems : "center", textAlign: 'center' }}>
                        {element.name === '' ? 
                            <Typography variant='body1'>
                                Sol taraftan seçim yapınız
                            </Typography> 
                            : 
                            <Typography variant='body1'>
                                {element.name}
                            </Typography>
                        }
                        </CardBody>
                    </Card>
                    </div>
                    </GridItem>
                </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                <GridContainer justify='center'>
                {lowers.map((lead) => {
                    return (
                    <Draggable 
                    onStop={forceRerender} onDrag={forceRerender} 
                    position={null} key={lead._id}
                    >
                        <div id={lead._id} key={lead._id}>
                            <Lead lead={lead}  handleClick={action} color='warning'/>
                        </div>
                    </Draggable>
                    )
                })}
                </GridContainer>
            </GridItem>
            { lowers.map( l => {
                console.log(l)
                return(
                <Xarrow
                    style={{zIndex : -1}}
                    key = {l._id}
                    start={positionRef} //can be react ref
                    end={l._id} //or an id
                    color = "red"

                />
                )
            })}
            { uppers.map( l => {
                console.log(l)
                return(
                <Xarrow
                    style={{zIndex : -1}}
                    key = {l._id}
                    end={positionRef} //can be react ref
                    start={l._id} //or an id
                />
                )
            })}
        </GridContainer>
    )
}

export default Hierarchy