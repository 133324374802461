export const text = {
    title : {
        tr : 'Departmanlar',
        en : 'Departments'
    },
    addButton : {
        tr : "Departman Ekle",
        en : "Add Department"
    },
    columns : {
        code : {
            tr : "Kodu",
            en : "Code"
        },
        name : {
            tr : "Departman Adı",
            en : "Department Name"
        },
        description : {
            tr : "Açıklama",
            en : 'Description'
        }
    }
}