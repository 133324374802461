import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DetailedInfo from 'components/HR/DetailedInfo';
import CompanyInfo from 'components/HR/CompanyInfo';
import PositionInfo from 'components/HR/PositionInfo';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import NavPills from 'components/NavPills/NavPills.js';
import avatar from 'assets/img/default-avatar.png';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { successAlert, warningAlert } from 'components/SweetAlerts/Alerts.js';

const useStyles = makeStyles(styles);

const KullaniciDetayi = (props) => {
  const classes = useStyles();
  // const { person } = props.location.state;
  const [alert, setAlert] = useState(null);
  const [person, setPerson] = useState(props.location.state.person);

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <GridContainer>
      <GridItem xs={8}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <NavPills
                  color='rose'
                  tabs={[
                    {
                      tabButton: 'General Info',
                      tabContent: (
                        <DetailedInfo
                          person={person}
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                          setAlert={setAlert}
                          hideAlert={hideAlert}
                          setPerson={setPerson}
                        />
                      ),
                    },
                    {
                      tabButton: 'Company Info',
                      tabContent: (
                        <CompanyInfo
                          person={person}
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                          setAlert={setAlert}
                          hideAlert={hideAlert}
                          setPerson={setPerson}
                        />
                      ),
                    },
                    {
                      tabButton: 'Position Info',
                      tabContent: (
                        <PositionInfo
                          person={person}
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                          setAlert={setAlert}
                          hideAlert={hideAlert}
                          setPerson={setPerson}
                        />
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={4}>
        <Card profile>
          <CardAvatar profile>
            <a href='#pablo' onClick={(e) => e.preventDefault()}>
              <img
                src={avatar}
                alt={person.name.first + ' ' + person.name.family}
              />
            </a>
          </CardAvatar>
          <CardBody profile>
            <h6 className={classes.cardCategory}>
              Company: {person.company.name}
            </h6>
            <h3 className={classes.cardTitle}>
              Name : {person.name.first + ' ' + person.name.family}
            </h3>
            <p className={classes.description}>
              Phone : {person.phone ? person.phone : '-'}
            </p>
            <p className={classes.description}>
              Email:{' '}
              <a mailto={person.email}>{person.email ? person.email : '-'}</a>
            </p>
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

KullaniciDetayi.propTypes = {
  location: PropTypes.object.isRequired,
};

export default KullaniciDetayi;
