import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getpositions } from '_services/position';

import GridItem from 'components/Grid/GridItem.js';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button.js';
import EditIcon from '@material-ui/icons/Edit';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(styles);

const PositionInfo = (props) => {
  const classes = useStyles();
  const { person } = props;
  const [editable, setEditable] = useState(false);
  const [position, setPosition] = useState([]);
  const [positions, setPositions] = useState([]);
  const [reportsToAsPerson, setReportsToAsPerson] = useState([]);

  const handleSubmit = () => {
    console.log('handle submit');
  };

  useEffect(() => {
    if (person.position) {
      setPosition(person.position);
    }
    getpositions().then((res) => {
      if (res.data && res.data.code && res.data.code === 200) {
        setPositions(res.data.data);
      }
    });
  }, []);

  const handlePosChange = (event) => {
    setPosition(event.target.value);
  };
  const handleManChange = (event) => {
    setReportsToAsPerson(event.target.value);
  };

  return (
    <GridContainer>
      <GridItem xs={11}>
        <GridContainer justify='flex-end'>
          <GridItem xs={2}>
            <Button
              block
              justIcon
              simple
              size='lg'
              color='success'
              onClick={() => setEditable(!editable)}
            >
              <EditIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id='demo-mutiple-chip-label'>Chip</InputLabel>
          <Select
            disabled={!editable}
            fullWidth
            labelId='demo-mutiple-chip-label'
            id='demo-mutiple-chip'
            multiple
            value={position}
            onChange={handlePosChange}
            input={<Input id='select-multiple-chip' />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => {
                  return (
                    <Chip key={value} label={value} className={classes.chip} />
                  );
                })}
              </div>
            )}
          >
            {positions.map((pos) => (
              <MenuItem key={pos._id} value={pos._id}>
                {pos.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridItem>
      {person.reportsToAsPerson ? (
        <GridItem xs={12} md={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-mutiple-chip-label'>Manager</InputLabel>
            <Select
              disabled={!editable}
              fullWidth
              labelId='demo-mutiple-chip-label'
              id='demo-mutiple-chip'
              multiple
              value={reportsToAsPerson}
              onChange={handleManChange}
              input={<Input id='select-multiple-chip' />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              {person.reportsToAsPerson.map((man) => (
                <MenuItem key={man._id} value={man._id}>
                  {man.name.first + ' ' + man.name.family}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
      ) : (
        <GridItem xs={12}>Manager: -</GridItem>
      )}
      <GridItem xs={11}>
        <GridContainer justify='flex-end'>
          <GridItem xs={2}>
            {editable && (
              <Button color='success' block onClick={handleSubmit}>
                Save
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

PositionInfo.propTypes = {
  person: PropTypes.object.isRequired,
};

export default PositionInfo;
