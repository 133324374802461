import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPerson } from '_services/person';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Select } from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
const useStyles = makeStyles(styles);

const AddPersonDialog = (props) => {
  const classes = useStyles();

  const { open, toggle, setAlertMessage } = props;
  const [firstName, setFirstName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [positions, setPositions] = useState([]);
  const [reportsToAsPerson, setReportsToAsPerson] = useState([]);
  const [alert, setAlert] = useState('');

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneNumberRegex = /(05|5)[0-9][0-9][1-9]([0-9]){6}/;

  const testEmail = (email) => {
    return emailRegex.test(email);
  };
  const testPhoneNumber = (phoneNumber) => {
    return phoneNumberRegex.test(phoneNumber);
  };

  const handleSubmit = () => {
    if (firstName === '') {
      setAlert('Ad kısmı boş bırakılamaz');
    } else if (familyName === '') {
      setAlert('Soyad kısmı boş bırakılamaz');
    } else if (phone !== '' && !testPhoneNumber(phone)) {
      setAlert('Hatalı telefon numarası girdiniz');
    } else if (email !== '' && !testEmail(email)) {
      setAlert('Hatalı email adresi girdiniz.');
    } else {
      setAlert('');
      const data = {
        name: { first: firstName, family: familyName },
        phone: phone,
        profilePhoto: '',
        email: email,
        // company: company,
        // positions: positions,
        // reportsToAsPerson: reportsToAsPerson,
      };
      createPerson(data)
        .then((res) => {
          if (res.data.code === 200) {
            setAlertMessage('Kişi ekleme tamamlandı');
            toggle();
            setFirstName('');
            setFamilyName('');
            setEmail('');
            setPhone('');
            setCompany('');
            setPositions([]);
            setReportsToAsPerson([]);
          } else {
            setAlertMessage('Bir sorunla karşılaşıldı');
          }
        })
        .catch((err) => {
          console.log('hata oluştu');
        });
    }
  };

  const handlePositionSelect = (e) => {
    setPositions(e.target.value);
  };

  const handleManagerSelect = (e) => {
    setReportsToAsPerson(e.target.value);
  };

  return (
    <Dialog open={open} onClose={toggle} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Kişi Ekle</DialogTitle>
      <DialogContent>
        {alert !== '' && <SnackbarContent message={alert} color='danger' />}
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText='Ad'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'firstName',
                value: firstName,
                onChange: (e) => {
                  setFirstName(e.target.value);
                },
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText='Soyad'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'familyName',
                value: familyName,
                onChange: (e) => {
                  setFamilyName(e.target.value);
                },
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText='Telefon Numarası'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'telNo',
                value: phone,
                onChange: (e) => {
                  setPhone(e.target.value);
                },
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText='Email'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'email',
                value: email,
                onChange: (e) => {
                  setEmail(e.target.value);
                },
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12}>
            <FormControl fullWidth classes={classes.selectFromControl}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                Şirket
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='Deneme1'
                >
                  Şirket Seçiniz
                </MenuItem>

                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='Deneme1'
                >
                  Deneme1
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='Deneme2'
                >
                  Deneme2
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='Deneme3'
                >
                  Deneme3
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              size='lg'
              simple
              color='success'
              block
              onClick={handleSubmit}
            >
              Ekle
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};

AddPersonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AddPersonDialog;
