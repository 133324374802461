import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { changePassword } from '../../_services/user';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';
import SweetAlert from 'react-bootstrap-sweetalert';

import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
const useStyles = makeStyles(styles);

const ChangePasswordDialog = (props) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState('');
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const [user] = useAtom(userAtom);
  const [basariAlert, setBasariAlert] = useState(null);
  const { open, toggle } = props;

  const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
  const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
  const numberRegex = new RegExp('^(?=.*[0-9])');
  const specialCharRegex = new RegExp('^(?=.[!@#$%^&])');

  const titleAndTextAlert = () => {
    setBasariAlert(
      <SweetAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title='İşlem Başarılı!'
        onConfirm={() => {
          toggle();
          setBasariAlert(null);
        }}
        onCancel={() => setBasariAlert(null)}
        confirmBtnCssClass={classes.button + ' ' + classes.info}
      >
        Şifre Değiştirme İşlemi Başarılı
      </SweetAlert>
    );
  };
  const success = () => {
    setAlert('');
    const token = user.user.token;
    const data = {
      _id: user.user._id,
      currentPassword,
      password
    };
    changePassword(data, token)
      .then((res) => {
        if (res.data.code === 200) {
          setCurrentPassword('');
          setPassword('');
          setPasswordRepeat('');
          titleAndTextAlert();
        } else {
          setAlert(res.data.message);
        }
      })
      .catch((err) => setAlert('Bir hata oluştu!'));
  };

  const handleSubmit = () => {
    let test = true;
    if (password.length < 8 || password.length > 15) {
      test = false;
      setAlert('Yeni şifreniz en az 8 en fazla 15 karakterden oluşmalıdır.');
      return;
    }
    if (!lowerCaseRegex.test(password)) {
      test = false;
      setAlert('Yeni şifreniz a-z gibi bir küçük harf içermelidir.');
      return;
    }
    if (!upperCaseRegex.test(password)) {
      test = false;
      setAlert('Yeni şifreniz A-Z gibi bir büyük harf içermelidir.');
      return;
    }
    if (!numberRegex.test(password)) {
      test = false;
      setAlert('Yeni şifreniz 0-9 gibi bir rakam içermelidir.');
      return;
    }
    if (!specialCharRegex.test(password)) {
      test = false;
      setAlert('Yeni şifreniz "!@#$%^&" gibi bir özel karakter içermelidir.');
      return;
    }
    if (test) {
      success();
    }
  };

  const handleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <Dialog open={open} onClose={toggle} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Şifre Değiştir</DialogTitle>
      <DialogContent>
        {basariAlert}
        {alert !== '' && <SnackbarContent message={alert} color='danger' />}
        <CustomInput
          labelText='Mevcut Şifre'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: visible ? 'text' : 'password',
            name: 'password',
            value: currentPassword,
            onChange: (e) => setCurrentPassword(e.target.value),
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Şifre Göster'>
                  <IconButton color='secondary' onClick={handleVisibility}>
                    {visible ? (
                      <VisibilityIcon className={classes.inputAdornmentIcon} />
                    ) : (
                      <VisibilityOffIcon
                        className={classes.inputAdornmentIcon}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
        <CustomInput
          labelText='Yeni Şifre'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: visible ? 'text' : 'password',
            name: 'password',
            value: password,
            onChange: (e) => {
              setPassword(e.target.value);
              if (e.target.value !== passwordRepeat && passwordRepeat !== '') {
                setAlert('Yeni şifre ve tekrarı uyuşmuyor.');
              } else {
                setAlert('');
              }
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Şifre Göster'>
                  <IconButton color='secondary' onClick={handleVisibility}>
                    {visible ? (
                      <VisibilityIcon className={classes.inputAdornmentIcon} />
                    ) : (
                      <VisibilityOffIcon
                        className={classes.inputAdornmentIcon}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
        <CustomInput
          labelText='Yeni Şifre Tekrar'
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: visible ? 'text' : 'password',
            name: 'password',
            value: passwordRepeat,
            onChange: (e) => {
              setPasswordRepeat(e.target.value);
              if (e.target.value !== password) {
                setAlert('Yeni şifre ve tekrarı uyuşmuyor.');
              } else {
                setAlert('');
              }
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Tooltip title='Şifre Göster'>
                  <IconButton color='secondary' onClick={handleVisibility}>
                    {visible ? (
                      <VisibilityIcon className={classes.inputAdornmentIcon} />
                    ) : (
                      <VisibilityOffIcon
                        className={classes.inputAdornmentIcon}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setCurrentPassword('');
            setPasswordRepeat('');
            setPassword('');
            setAlert('');
            toggle();
          }}
          color='danger'
        >
          Vazgeç
        </Button>
        <Button
          onClick={handleSubmit}
          color='rose'
          disabled={
            currentPassword === '' ||
            password === '' ||
            password !== passwordRepeat
          }
        >
          DEĞİŞTİR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
