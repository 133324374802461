import React from 'react';

const AddHalfProduct = () => {
  return (
    <div>
      <h1>add half product</h1>
    </div>
  );
};

export default AddHalfProduct;
