import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export const successAlert = (setAlert, hideAlert, classes, message) => {
  setAlert(
    <SweetAlert
      success
      style={{ display: 'block', marginTop: '-100px' }}
      title='Success'
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnCssClass={classes.button + ' ' + classes.success}
    >
      <h5>{message}</h5>
    </SweetAlert>
  );
};

export const warningAlert = (setAlert, hideAlert, classes, message) => {
  setAlert(
    <SweetAlert
      warning
      style={{ display: 'block', marginTop: '-100px' }}
      title='Warning'
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnCssClass={classes.button + ' ' + classes.success}
    >
      <h5>{message}</h5>
    </SweetAlert>
  );
};
