import React from 'react';
import { useAtom } from 'jotai';
import { userAtom } from '../_atoms/user';
import { logout } from '../_services/user.js';
import Button from 'components/CustomButtons/Button.js';

function NotAuthenticated() {
  const [user, setUser] = useAtom(userAtom);
  const handleLogout = () => {
    const data = { _id: user.user._id };
    const token = user.user.token;
    logout(data, token)
      .then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setUser({ loggedIn: false });
        }
      })
      .catch((err) => console.log(err));
  };
  const styles = {
    div: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    button: {
      width: '150px',
      height: '100px',
      fontSize: '25px'
    }
  };
  return (
    <div style={styles.div}>
      <p>Yetkilendirilmemiş kullanıcı</p>
      <Button onClick={handleLogout}>Çıkış Yap</Button>
    </div>
  );
}

export default NotAuthenticated;
