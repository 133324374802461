import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProducts } from '_services/products'
import { getProductGroups } from '_services/products';
import { useAtom } from 'jotai';
import { userAtom } from '../../../_atoms/user';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import DataGrid from 'components/DataGrid/SimpleGrid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';

const Products = () => {
    const [ user ] = useAtom(userAtom);
    const [ products, setProducts ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ productGroups, setProductGroups] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if(user.user && user.user.token){
            const data = { isFullProduct : true }
            setLoading(true)
            getProducts(user.user.token, data)
            .then( res => {
                setLoading(false)
                if(res.data && res.data.code === 200){
                    setProducts(res.data.data)
                }
            })
            .catch( err => setLoading(false))
        }
    }, [user])

    useEffect(() => {
        getProductGroups(user.user.token)
          .then((res) => {
            if (res.data && res.data.code && res.data.code === 200) {
              setProductGroups(res.data.data);
            }
          })
          .catch((err) => console.error(err));
    }, [user]);

    const columns = [
        { name: 'photo', title: 'Photo'},
        { name: 'code', title: 'Code'},
        { name: 'name', title: 'Name'},
        { name: 'action', title: 'Action'},
    ]

    const defaultColumnWidths = [
        { columnName: 'code', width: 100 },
        { columnName: 'name', width: 600 },
        { columnName: 'action', width: 100 },
        { columnName: 'photo', width: 200 },
    ]

    const rows = () => {
        return (
        products.map( l => ({
            code : l.code,
            name : l.name,
            photo: <img src={l.photoUrl} style={{height : 100, width : 200, objectFit : "contain" }} alt={l.name} />,
            action :    <Tooltip title="Details">
                            <IconButton color='secondary' onClick={() => alert()} >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
            })
            )
        )
    }

    return (
        <GridContainer>
            <GridItem xs={12}>
                <GridContainer justify='flex-end'>
                <GridItem xs={12} sm={2}>
                    <Link to={{ pathname: '/crm/add-product', isFullProduct: true }}>
                    <Button color='success'>
                        <AddCircleOutlineIcon />
                        Add Product
                    </Button>
                    </Link>
                </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12}>
                    {
                    loading
                    ?   <Paper style={{display : 'flex' , height : 300, justifyContent : "center", alignItems : "center"}}>
                            <CircularProgress color="secondary" />
                        </Paper>
                    :   <Card>
                            <CardHeader color='rose' icon>
                            <CardText color='rose'>
                                <h4>Products</h4>
                            </CardText>
                            </CardHeader>
                            <CardBody> 
                                <GridContainer justify = 'center'>
                                    <GridItem xs={12}>
                                        <Link to={{ pathname: '/crm/product-groups', productGroups }}>
                                            <Button color='success' style={{marginRight : 15, marginBottom : 15}}>
                                                <AddCircleOutlineIcon />
                                                Product Groups
                                            </Button>
                                        </Link>
                                        { productGroups.map( group => {
                                            return(
                                                <Button color='success' key = {group._id} style={{marginRight : 15, marginBottom : 15}}>
                                                    {group.name}
                                                </Button> 
                                            )
                                        })}
                                    </GridItem> 
                                    <GridItem xs={12}>
                                        <DataGrid
                                            columns = {columns}
                                            rows = { rows() }
                                            defaultColumnWidths = {defaultColumnWidths}
                                            lang = {user.user.language}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    }
                    
            </GridItem>
        </GridContainer>
    );
};

export default Products;
