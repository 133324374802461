import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { getCampaignTypes } from '_services/campaignType';
import CampaignType from './CampaignType';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';

const CampaignTypes = () => {
  const [user] = useAtom(userAtom);
  const [campaignTypes, setCampaignTypes] = useState([]);
  useEffect(() => {
    getCampaignTypes({}, user.user.token).then((res) => {
      if (res.data && res.data.code && res.data.code === 200) {
        setCampaignTypes(res.data.data);
      }
    });
  }, [user]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <Link
            to={{ pathname: '/crm/add-campaign-type', state: { edit: false } }}
          >
            <Button color='success'>
              <AddCircleOutlineIcon />
              Add Campaıgn Type
            </Button>
          </Link>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <GridContainer>
          {campaignTypes.map((campaign) => {
            return <CampaignType key={campaign.name} campaign={campaign} />;
          })}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default CampaignTypes;
