export const groupingPanelMessages = {
    en : {},
    tr : {
        groupByColumn : "Gruplamak istediğiniz kolonu bu alana sürükleyip bırakınız."
    }
}

export const tableMessages = {
    en : {},
    tr : {
        noData: 'Gösterilecek veri yok',
    },
    de : {
        noData: 'Keine Daten verfügbar',
    }
};

export const filterRowMessages = {
    en : {},
    tr : {
        filterPlaceholder: 'Filtrele...',
    },
    de : {
        filterPlaceholder: 'Filter...',
    }
};

export const pagingPanelMessages = {
    en : {},
    tr : {
        showAll: 'Tümü',
        rowsPerPage: 'Sayfadaki kayıt sayısı',
        info: '{count} kayıttan {from} - {to} gösteriliyor.',
    },
    de : {
        showAll: 'Alle',
        rowsPerPage: 'Zeilen pro Seite',
        info: 'Zeilen {from} bis {to} ({count} Elemente)',
    }

}