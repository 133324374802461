import React, { useState, useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import NavPills from 'components/NavPills/NavPills.js';
import avatar from 'assets/img/default-avatar.png';
import GeneralInfo from 'components/Company/GeneralInfo';
import Contacts from 'components/Company/Contacts';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { successAlert, warningAlert } from 'components/SweetAlerts/Alerts.js';
const useStyles = makeStyles(styles);

const CompanyDetails = (props) => {
  const classes = useStyles();
  const { company } = props.location.state;
  const [alert, setAlert] = useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <GridContainer>
      <GridItem xs={8}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <NavPills
                  color='rose'
                  tabs={[
                    {
                      tabButton: 'General Info',
                      tabContent: (
                        <GeneralInfo
                          company={company}
                          successAlert={successAlert}
                          warningAlert={warningAlert}
                          setAlert={setAlert}
                          hideAlert={hideAlert}
                        />
                      ),
                    },
                    {
                      tabButton: 'Order Actions',
                      tabContent: (
                        <GridItem xs={12}>
                          <Typography variant='body1'>
                            Name : {company.name}
                          </Typography>
                          <Typography variant='body1'>
                            Code : {company.code}
                          </Typography>
                          <Typography variant='body1'>
                            Nickname : {company.nickName}
                          </Typography>
                          <Typography variant='body1'>
                            Fax : {company.fax}
                          </Typography>
                          <Typography variant='body1'>
                            Email: {company.email}
                          </Typography>
                          <Typography variant='body1'>
                            Phone: {company.phone}
                          </Typography>
                          <Typography variant='body1'>
                            Trade Registration Number : {company.ticSicil}
                          </Typography>
                          <Typography variant='body1'>
                            Tax Office : {company.vergiDairesi}
                          </Typography>
                        </GridItem>
                      ),
                    },
                    {
                      tabButton: 'Contacts',
                      tabContent: <Contacts company={company} />,
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={4}>
        <Card profile>
          <CardAvatar profile>
            <a href='#pablo' onClick={(e) => e.preventDefault()}>
              <img src={company.logoUrl || avatar} alt={company.name} />
            </a>
          </CardAvatar>
          <CardBody profile>
            <h6 className={classes.cardCategory}> Code : {company.code}</h6>
            <h3 className={classes.cardTitle}>{company.name}</h3>
            <p className={classes.description}>Phone : 0{company.phone}</p>
            <p className={classes.description}>
              Website :{' '}
              <a
                href={'http://' + company.website}
                target='_blank'
                rel='noopener'
              >
                {company.website}
              </a>
            </p>
            <p className={classes.description}>Address : {company.address}</p>
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default CompanyDetails;
