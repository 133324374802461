import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getProductGroups } from '_services/products';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import AddRawMaterialDialog from 'components/Product/AddRawMaterialDialog';
import ChooseRawMaterialDialog from 'components/Product/ChooseRawMaterialDialog';
import AddHalfProductDialog from 'components/Product/AddHalfProductDialog';
import ChooseHalfProductDialog from 'components/Product/ChooseHalfProductDialog';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  successAlert,
  warningAlert,
} from '../../components/SweetAlerts/Alerts';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles(styles);

const AddFullProduct = () => {
  const history = useHistory();
  const classes = useStyles();

  const [user] = useAtom(userAtom);
  const [swal, setSwal] = useState(null);
  const [name, setName] = useState('');
  const [alert, setAlert] = useState('');
  const [code, setCode] = useState('');
  const [productGroups, setProductGroups] = useState([]);
  const [productGroup, setProductGroup] = useState('');

  const [showAddRawDialog, setShowAddRawDialog] = useState(false);
  const [showChooseRawDialog, setShowChooseRawDialog] = useState(false);
  const [showAddHalfDialog, setShowAddHalfDialog] = useState(false);
  const [showChooseHalfDialog, setShowChooseHalfDialog] = useState(false);

  const [alertMessage, setAlertMessage] = useState('');

  const handleSubmit = () => {
    console.log('handle submit');
  };

  useEffect(() => {
    getProductGroups(user.user.token)
      .then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setProductGroups(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  }, [user]);

  const handleAddRaw = () => {
    setShowAddRawDialog(!showAddRawDialog);
  };

  const handleChooseRaw = () => {
    setShowChooseRawDialog(!showChooseRawDialog);
  };

  const handleAddHalf = () => {
    setShowAddHalfDialog(!showAddHalfDialog);
  };

  const handleChooseHalf = () => {
    setShowChooseHalfDialog(!showChooseHalfDialog);
  };

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              justIcon
              color='transparent'
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </Button>
            <h4 className={classes.cardTitle}>Add Full Product</h4>
          </div>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer justify='flex-end'>
          {alert !== '' && (
            <GridItem xs={12}>
              <SnackbarContent message={alert} color='danger' />
            </GridItem>
          )}
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Code'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'code',
                value: code,
                onChange: (e) => {
                  setCode(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: '11px' }}
            >
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                Choose Product Group
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={productGroup}
                onChange={(e) => setProductGroup(e.target.value)}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                {productGroups.map((grp) => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={grp.name}
                      key={grp._id}
                    >
                      {grp.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem
                xs={12}
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  martinBottom: '20px',
                }}
              >
                <Typography variant='h5'>Raw Material</Typography>
              </GridItem>
              <GridItem xs={6}>
                <Button color='info' block onClick={() => handleAddRaw()}>
                  Add Raw Materıal
                </Button>
                <GridContainer>
                  <GridItem xs={12}>
                    <AddRawMaterialDialog
                      open={showAddRawDialog}
                      toggle={handleAddRaw}
                      setAlertMessage={setAlertMessage}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6}>
                <Button color='info' block onClick={() => handleChooseRaw()}>
                  Choose Raw Materıal
                </Button>
                <GridContainer>
                  <GridItem xs={12}>
                    <ChooseRawMaterialDialog
                      open={showChooseRawDialog}
                      toggle={handleChooseRaw}
                      setAlertMessage={setAlertMessage}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem
                xs={12}
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  martinBottom: '20px',
                }}
              >
                <Typography variant='h5'>Half Product</Typography>
              </GridItem>
              <GridItem xs={6}>
                <Button color='info' block onClick={() => handleAddHalf()}>
                  Add Half Product
                </Button>
                <GridContainer>
                  <GridItem xs={12}>
                    <AddHalfProductDialog
                      open={showAddHalfDialog}
                      toggle={handleAddHalf}
                      setAlertMessage={setAlertMessage}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={6}>
                <Button color='info' block onClick={() => handleChooseHalf()}>
                  Choose Half Product
                </Button>
                <GridContainer>
                  <GridItem xs={12}>
                    <ChooseHalfProductDialog
                      open={showChooseHalfDialog}
                      toggle={handleChooseHalf}
                      setAlertMessage={setAlertMessage}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Button color='success' block onClick={handleSubmit}>
              Submıt
            </Button>
          </GridItem>
          {swal}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default AddFullProduct;
