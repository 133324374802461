import React, {useState} from 'react'
import { getProductGroups } from '_services/products';
import AddProductGroupDialog from 'components/Product/AddProductGroupDialog.js'
import { makeStyles } from '@material-ui/core/styles';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import avatar from 'assets/img/default-avatar.png';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { warningAlert, successAlert } from 'components/SweetAlerts/Alerts.js'
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles(styles)
const useStyles2 = makeStyles(styles2);

const ProductGroups = props => {
    const [user] = useAtom(userAtom);
    const [productGroups, setProductGroups] = useState(props.location.productGroups);
    const [ PGDialog, SetPGDialog ] = useState(false)
    const [ group, setGroup ] = useState({})

    const PGDialogToggle = () => {
      SetPGDialog(!PGDialog)
    }

    const classes = useStyles();
    const classes2 = useStyles2();

    const fetchData = () => {
        getProductGroups(user.user.token)
        .then((res) => {
            if (res.data && res.data.code && res.data.code === 200) {
                setProductGroups(res.data.data);
            }
        })
        .catch((err) => console.error(err));
    }

    return(
        <GridContainer justify='flex-start'>
            <GridItem xs={12}>
                <GridContainer justify='flex-end'>
                    <GridItem xs={12} md={2}>
                        <Button color='success' block onClick={PGDialogToggle}>
                        <AddCircleOutlineIcon />
                            New Product Group
                        </Button>
                    </GridItem>
                </GridContainer>
            </GridItem>
            {productGroups.map( g => {
                return(
                    <GridItem key={g._id} xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card>
                            <CardHeader color="rose" stats icon>
                                <CardIcon color="rose">
                                    <Icon>content_copy</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>
                                    {g.shortName}
                                </p>
                                <h3 className={classes.cardTitle}>
                                    {g.name}   
                                </h3>
                            </CardHeader>
                            <CardBody profile>
                                <h6 className={classes.cardCategory}> Dynamic Fields</h6>
                                {g.dynamicFields.map( (f, i) => {
                                    return(
                                    <span className={classes.description} key={f._id}>{f.name}({f.unit}){ i+1 === g.dynamicFields.length ? '' : ' / '  }</span>
                                    )
                                })}
                            </CardBody>
                            <CardFooter>
                                <GridContainer justify='flex-end'>
                                    <GridItem>
                                        <IconButton color="secondary" size='small' aria-label="add to shopping cart" onClick={() => {
                                            PGDialogToggle()
                                            setGroup(g)
                                        }} >
                                            <AddIcon />
                                        </IconButton> 
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>
                        </Card>
                    </GridItem>
                )
            })}
            <AddProductGroupDialog
                open={PGDialog}
                toggle={PGDialogToggle}
                token = {user.user.token}
                fetchData = {fetchData}
                group = { group }
            />
        </GridContainer>
    )
}

export default ProductGroups