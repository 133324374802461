import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getForgotPasswordCode, checkUser, setPasswordApi } from '../../_services/user';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';


const useStyles = makeStyles(styles);


const ForgotPasswordDialog = (props) => {
  const classes = useStyles();
  const [alert, setAlert] = useState('');
  const [ phone, setPhone ] = useState('');
  const [ typeCode, setTypeCode ] = useState(false)
  const [ visible, setVisible] = useState(false);
  const [ passwordRepeat, setPasswordRepeat ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ user, setUser ] = useState('')
  const [ readyToSendCode, setReadyToSendCode ] = useState(false)
  const [ noUser, setNoUser ] = useState(false)
  const { open, toggle } = props;
  const [ code, setCode ] = useState('')
  
  const handleSubmit = () => {
    getForgotPasswordCode(phone).then((res) => {
      if(res.data && res.data.code && res.data.code === 200){
        setTypeCode(true)
      } 
    });
  };

  const handleVisibility = () => {
    setVisible(!visible);
  };

  const handleSetPassword = () => {
    const data = { code, password, phone }
    setPasswordApi(data)
    .then(res => console.log(res.data))
  }

  const handlePhoneChange = (e) => {
    const { value } = e.target
    setPhone(value)
    setUser('')
    setNoUser(false)
    setReadyToSendCode(false)
    if(value.length === 10){
      checkUser(value)
      .then(res => {
        if(res.data.code === 200){
          setReadyToSendCode(true)
          setUser(res.data.data)
        }else{
          setNoUser(true)
          setReadyToSendCode(true)
          setUser('')
        }
      }).catch(err => null)
    }
  }
  const handleVazgec = () => {
    setReadyToSendCode(false)
    setUser('')
    setNoUser(false)
    setPhone('')
  }

  return (
    <Dialog open={open} onClose={toggle} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Şifremi Unuttum</DialogTitle>
      <DialogContent>
        { typeCode
          ? <>
              <CustomInput
                labelText='Kod'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: code,
                  type: 'number',
                  onChange: e => setCode(e.target.value),
                }}
              /> 
              <CustomInput
                labelText='Yeni Şifre'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: visible ? 'text' : 'password',
                  name: 'password',
                  value: password,
                  onChange: (e) => {
                    setPassword(e.target.value);
                    if (e.target.value !== passwordRepeat && passwordRepeat !== '') {
                      setAlert('Yeni şifre ve tekrarı uyuşmuyor.');
                    } else {
                      setAlert('');
                    }
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip title='Şifre Göster'>
                        <IconButton color='secondary' onClick={handleVisibility}>
                          {visible ? (
                            <VisibilityIcon className={classes.inputAdornmentIcon} />
                          ) : (
                            <VisibilityOffIcon
                              className={classes.inputAdornmentIcon}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
              <CustomInput
                labelText='Yeni Şifre Tekrar'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: visible ? 'text' : 'password',
                  name: 'password',
                  value: passwordRepeat,
                  onChange: (e) => {
                    setPasswordRepeat(e.target.value);
                    if (e.target.value !== password) {
                      setAlert('Yeni şifre ve tekrarı uyuşmuyor.');
                    } else {
                      setAlert('');
                    }
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip title='Şifre Göster'>
                        <IconButton color='secondary' onClick={handleVisibility}>
                          {visible ? (
                            <VisibilityIcon className={classes.inputAdornmentIcon} />
                          ) : (
                            <VisibilityOffIcon
                              className={classes.inputAdornmentIcon}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </>
          : <CustomInput
              labelText='Telefon'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: phone,
                type: 'number',
                onChange: handlePhoneChange,
                // endAdornment: (
                //   <InputAdornment position='end'>
                //     <Face className={classes.inputAdornmentIcon} />
                //   </InputAdornment>
                // )
              }}
            />
           
        }
        { readyToSendCode &&  
          <Typography variant='h6' gutterBottom>{noUser ? 'Kullanici Bulunamadi' : user + ' bulundu'}</Typography>
        }
        
      </DialogContent>
      <DialogActions>
        { readyToSendCode && user !== '' && !typeCode &&
        <>
          <Button simple size='lg' color='success' onClick={handleSubmit}> 
            Kodu Gönder
          </Button>
          <Button simple size='lg' color='danger' onClick={handleVazgec}> 
          Vazgeç
        </Button>
        </>
        }
        {
          typeCode &&
          <Button simple size='lg' color='success' onClick={handleSetPassword}> 
            Kodu Onayla
          </Button>
        }

      </DialogActions>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
