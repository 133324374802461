import React, { useState, useEffect } from 'react';
import { getPerson } from '_services/person';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@material-ui/core';
import Person from 'components/Person/Person';

const Contacts = (props) => {
  const { company } = props;
  const [persons, setPersons] = useState([]);
  useEffect(() => {
    getPerson()
      .then((res) => {
        let workers = [];
        res.data.data.map((person) => {
          if (person.company && person.company._id === company._id) {
            workers.push(person);
          }
        });
        console.log(workers);
        setPersons(workers);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <GridItem xs={12}>
      <GridContainer>
        {persons.map((person) => {
          return <Person person={person} key={person._id} />;
        })}
      </GridContainer>
    </GridItem>
  );
};

export default Contacts;
