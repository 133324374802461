import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { auth } from './_services/user';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/Auth.js';
import AdminLayout from 'layouts/Admin.js';
import ErpLayout from 'layouts/ErpLayout';
import CrmLayout from 'layouts/CrmLayout';
import ProjeLayout from 'layouts/ProjeLayout';
import MaliyetAnaliziLayout from 'layouts/MaliyetAnaliziLayout';
import MuhasebeLayout from 'layouts/MuhasebeLayout';
import { useAtom } from 'jotai';
import { userAtom } from './_atoms/user';
import NotAuthenticated from './views/NotAuthenticated';
const hist = createBrowserHistory();

const App = (props) => {
  const [user, setUser] = useAtom(userAtom);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token !== '' && !user.loggedIn) {
      auth(token).then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setUser({ user: res.data.data, loggedIn: true });
        }
      });
    }
  }, [token]);

  const permissions = (user) => {
    let url = '/yetkilindirilmemis';
    if (user.layoutPermissions.length > 0) {
      url = `/${user.layoutPermissions[0]}/anasayfa`;
    }
    return url;
  };

  return (
    <Router history={hist}>
      <Switch>
        <Route
          path='/auth'
          render={() => {
            return user.loggedIn ? (
              <Redirect to={permissions(user.user)} />
            ) : (
              <AuthLayout to='/login' />
            );
          }}
        />
        <Route
          path='/admin'
          render={() => {
            return user.loggedIn ? (
              <AdminLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Route
          path='/erp'
          render={() => {
            return user.loggedIn ? (
              <ErpLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Route
          path='/crm'
          render={() => {
            return user.loggedIn ? (
              <CrmLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Route
          path='/project-management'
          render={() => {
            return user.loggedIn ? (
              <ProjeLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Route
          path='/cost-analysis'
          render={() => {
            return user.loggedIn ? (
              <MaliyetAnaliziLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Route
          path='/accounting'
          render={() => {
            return user.loggedIn ? (
              <MuhasebeLayout {...user} />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Route
          path='/unauthorized'
          render={() => {
            return user.loggedIn ? (
              <NotAuthenticated />
            ) : (
              <Redirect to='/auth/login' />
            );
          }}
        />
        <Redirect
          from='/'
          to={user.loggedIn ? '/admin/dashboard' : '/auth/login'}
        />
      </Switch>
    </Router>
  );
};

export default App;
