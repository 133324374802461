import React, { useState, useEffect } from 'react';
import { updatePerson } from '_services/person';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import EditIcon from '@material-ui/icons/Edit';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js';
import { successAlert } from 'components/SweetAlerts/Alerts';
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const DetailedInfo = (props) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const {
    person,
    succesAlert,
    warningAlert,
    setAlert,
    hideAlert,
    setPerson,
  } = props;
  const [editable, setEditable] = useState(false);
  const [firstName, setFirstName] = useState(person.name.first);
  const [familyName, setFamilyName] = useState(person.name.family);
  const [email, setEmail] = useState(person.email);
  const [phone, setPhone] = useState(person.phone);

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneNumberRegex = /(05|5)[0-9][0-9][1-9]([0-9]){6}/;

  const testEmail = (email) => {
    return emailRegex.test(email);
  };
  const testPhoneNumber = (phoneNumber) => {
    return phoneNumberRegex.test(phoneNumber);
  };
  const handleChange = () => {
    if (!firstName) {
      warningAlert(setAlert, hideAlert, classes2, 'Name field is required');
    } else if (!familyName) {
      warningAlert(
        setAlert,
        hideAlert,
        classes2,
        'Last name field is required'
      );
    } else if (email !== '' && !testEmail(email)) {
      warningAlert(
        setAlert,
        hideAlert,
        classes2,
        "Email address isn't in correct format."
      );
    } else if (phone !== '' && !testPhoneNumber(phone)) {
      warningAlert(
        setAlert,
        hideAlert,
        classes2,
        "Phone number isn't in correct format."
      );
    } else {
      setEditable(!editable);
      const data = {
        _id: person._id,
        name: {
          first: firstName,
          family: familyName,
        },
        email: email,
        phone: phone,
      };
      updatePerson(data)
        .then((res) => {
          successAlert(setAlert, hideAlert, classes2, 'Person updated!');
          setPerson(res.data.data);
        })
        .catch((err) => {
          warningAlert(setAlert, hideAlert, classes2, 'Process failed');
        });
    }
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <GridContainer justify='center'>
        <GridItem xs={11}>
          <GridContainer justify='flex-end'>
            <GridItem xs={2}>
              <Button
                block
                justIcon
                simple
                size='lg'
                color='success'
                onClick={() => setEditable(!editable)}
              >
                <EditIcon />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='First Name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'firstName',
              value: firstName,
              disabled: !editable,
              onChange: (e) => {
                setFirstName(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Last Name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'familyName',
              value: familyName,
              disabled: !editable,
              onChange: (e) => {
                setFamilyName(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Email'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'email',
              value: email,
              disabled: !editable,
              onChange: (e) => {
                setEmail(e.target.value);
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <CustomInput
            labelText='Phone'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'phone',
              value: phone,
              disabled: !editable,
              onChange: (e) => {
                setPhone(e.target.value);
              },
            }}
          />
        </GridItem>

        <GridItem xs={11}>
          <GridContainer justify='flex-end'>
            <GridItem xs={2}>
              {editable && (
                <Button color='success' block onClick={handleChange}>
                  Save
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

DetailedInfo.propTypes = {
  person: PropTypes.object.isRequired,
};

export default DetailedInfo;
