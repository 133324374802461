import React from 'react';

import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Typography from '@material-ui/core/Typography';
import Button from 'components/CustomButtons/Button';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const Person = (props) => {
  const { first, family } = props.person.name;
  const { positions, company, phone, email } = props.person;
  const history = useHistory();

  const handleUserDetails = () => {
    history.push('/admin/userdetail', { person: props.person });
  };

  return (
    <GridItem xs={6}>
      <Card>
        <CardBody style={{ textAlign: 'center' }}>
          <Typography variant='h5'>{first + ' ' + family}</Typography>
          {positions.map((pos) => {
            return (
              <Typography variant='h6' key={pos.name}>
                {pos.name}
              </Typography>
            );
          })}
          {company ? (
            <Typography variant='body1'>{company.name}</Typography>
          ) : (
            <Typography variant='body1'>No Company Info</Typography>
          )}
          {phone ? (
            <Typography variant='body1'>Tel: {phone}</Typography>
          ) : (
            <Typography variant='body1'>Tel: -</Typography>
          )}
          {email ? (
            <Typography variant='body1'>Email: {email}</Typography>
          ) : (
            <Typography variant='body1'>Email: -</Typography>
          )}

          <Button size='lg' color='rose' size='sm' onClick={handleUserDetails}>
            Open Profıle
          </Button>
        </CardBody>
      </Card>
    </GridItem>
  );
};

Person.propTypes = {
  person: PropTypes.object.isRequired,
};

export default Person;
