import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { getOperations } from '../../_services/operations';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import DataGrid from 'components/DataGrid/SimpleGrid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';

const Operations = () => {
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [operations, setOperations] = useState([]);

  const { language } = user.user

  useEffect(() => {
    if (user.user && user.user.token) {
      setLoading(true);
      getOperations(user.user.token)
        .then((res) => {
          setLoading(false);
          if (res.data && res.data.code === '200') {
            setOperations(res.data.data);
          }
        })
        .catch((err) => setLoading(false));
    }
  }, [user]);

  const columns = [
    { name: 'name', title: 'Operation Name' },
    { name: 'description', title: 'Operation Description' },
    { name: 'department', title: 'Operation Department' },
    { name: 'units', title: 'Operation Units' },
    { name: 'minutesPerUnits', title: 'Operation Minutes Per Units' },
    { name: 'code', title: 'Operation Code' },
  ];

  const defaultColumnWidths = [
    { columnName: 'name', width: 200 },
    { columnName: 'description', width: 200 },
    { columnName: 'department', width: 200 },
    { columnName: 'units', width: 200 },
    { columnName: 'minutesPerUnits', width: 200 },
    { columnName: 'code', width: 200 },
  ];

  const rows = () => {
    return operations.map((l) => ({
      name: l.name,
      description: l.description,
      department: l.department.name,
      units: l.units,
      minutesPerUnits: l.minutesPerUnit,
      code: l.code,
    }));
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <Link to='/erp/addoperation'>
            <Button color='success'>
              <AddCircleOutlineIcon />
              Add Operation
            </Button>
          </Link>
        </GridContainer>
        <GridItem xs={12}>
          {loading ? (
            <Paper
              style={{
                display: 'flex',
                height: 300,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color='secondary' />
            </Paper>
          ) : (
            <Card>
              <CardHeader color='rose' icon>
                <CardText color='rose'>
                  <h4>Operations</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <DataGrid
                  columns={columns}
                  rows={rows()}
                  defaultColumnWidths={defaultColumnWidths}
                  lang = {language}
                />
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default Operations;
