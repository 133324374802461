import React, { useState, useEffect } from 'react';
import { getpositions, getPositionHierarchy } from '../../_services/position';
import Typography from '@material-ui/core/Typography';
import Button from 'components/CustomButtons/Button.js';
import PozisyonEkleDialog from 'components/Yetkiler/PozisyonEkleDialog';
import SweetAlert from 'react-bootstrap-sweetalert';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import Hierarchy from 'components/Hierarchy/Hierarchy.js';

const useStyles = makeStyles(styles);

const Pozisyonlar = () => {
  const [show, setShow] = useState(false);
  const [positions, setPositions] = useState([]);
  const [message, setMessage] = useState('');
  const [successAlert, setSuccessAlert] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState('');
  const [managers, setManagers] = useState([]);
  const [leads, setLeads] = useState([]);

  const classes = useStyles();
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const data = {};
    getpositions(data)
      .then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setPositions(res.data.data);
        }
      })
      .catch((err) => null);
  };

  useEffect(() => {
    if (message !== '') {
      setSuccessAlert(
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title={message}
          onConfirm={() => {
            setSuccessAlert(null);
            setMessage('');
          }}
          onCancel={() => {
            setSuccessAlert(null);
            setMessage('');
          }}
          confirmBtnCssClass={classes.button + ' ' + classes.info}
        ></SweetAlert>
      );
    }
  }, [message]);

  const fetchHierarchy = (_id) => {
    const data = { position: _id };
    getPositionHierarchy(data)
      .then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setManagers(res.data.data.managers);
          setLeads(res.data.data.leads);
        }
      })
      .catch((err) => null);
  };

  const handleAction = (id, name) => {
    setSelectedPosition(name);
    fetchHierarchy(id);
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <Button color='success' onClick={handleShow}>
            <AddCircleOutlineIcon />
            Pozisyon Ekle
          </Button>
        </GridContainer>
      </GridItem>
      <PozisyonEkleDialog
        open={show}
        toggle={handleShow}
        positions={positions}
        setMessage={setMessage}
      />
      <GridItem xs={3}>
        <Card variant='outlined' style={{ height: 500 }}>
          <CardBody>
            <GridContainer justify='flex-start'>
              {positions !== [] ? (
                positions.map((pos) => (
                  <GridItem key={pos.name} xs={12}>
                    <Button
                      color={selectedPosition === pos.name ? 'success' : null}
                      onClick={() => {
                        handleAction(pos._id, pos.name);
                      }}
                      size='sm'
                      block
                    >
                      {pos.name}
                    </Button>
                  </GridItem>
                ))
              ) : (
                <></>
              )}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={9}>
        <Hierarchy
          element={{ name: selectedPosition }}
          uppers={managers}
          lowers={leads}
          action={handleAction}
        />
      </GridItem>
      {successAlert}
    </GridContainer>
  );
};

export default Pozisyonlar;
