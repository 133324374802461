import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(styles);

const CampaignType = (props) => {
  const classes = useStyles();
  const { channels, name, target, code, isIndividual } = props.campaign;

  return (
    <GridItem xs={12} md={6}>
      <Card>
        <CardHeader color='rose' icon>
          <CardText color='rose'>
            <h4 className={classes.cardTitle}>{name}</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <h6>Code: {code}</h6>
          <h5>Target: {target}</h5>
          <h5>For: {isIndividual ? 'Individual' : 'Corporate'}</h5>
          <h5>
            Communication Channels:{' '}
            <ul>
              {channels.map((channel) => {
                return <li key={channel}>{channel}</li>;
              })}
            </ul>
          </h5>
          <GridItem xs={12}>
            <GridContainer justify='flex-end'>
              <Link
                to={{
                  pathname: '/crm/add-campaign-type',
                  state: { edit: true, infos: props.campaign },
                }}
              >
                <Button color='success'>
                  <EditIcon />
                  Edit Type
                </Button>
              </Link>
            </GridContainer>
          </GridItem>
        </CardBody>
      </Card>
    </GridItem>
  );
};

CampaignType.propTypes = {
  campaign: PropTypes.object.isRequired,
};

export default CampaignType;
