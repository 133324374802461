import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { createDepartment } from '../../_services/department';
import {
  successAlert,
  warningAlert,
} from '../../components/SweetAlerts/Alerts';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
const useStyles = makeStyles(styles);

const AddDepartment = () => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [swal, setSwal] = useState(null);
  const [alert, setAlert] = useState('');
  const history = useHistory();
  const [user] = useAtom(userAtom);

  const hideAlert = () => {
    setSwal('');
    setName('');
    setDescription('');
  };

  const handleSubmit = () => {
    if (name === '') {
      setAlert('Name field is required');
    } else {
      const data = {
        name: name,
        description: description,
      };
      createDepartment(data, user.user.token)
        .then((res) => {
          if (res && res.data && res.data.code === 200) {
            successAlert(
              setSwal,
              hideAlert,
              classes,
              'Department successfully added'
            );
          }
        })
        .catch((err) => {
          warningAlert(
            setSwal,
            hideAlert,
            classes,
            'Department adding process failed'
          );
        });
    }
  };

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              justIcon
              color='transparent'
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </Button>
            <h4 className={classes.cardTitle}>Add Department</h4>
          </div>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer justify='flex-end'>
          {alert !== '' && (
            <GridItem xs={12}>
              <SnackbarContent message={alert} color='danger' />
            </GridItem>
          )}
          <GridItem xs={12} md={12}>
            <CustomInput
              labelText='Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={12}>
            <CustomInput
              labelText='Description'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'desc',
                value: description,
                onChange: (e) => {
                  setDescription(e.target.value);
                },
                multiline: true,
                rows: 4,
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Button color='success' block onClick={handleSubmit}>
              Submıt
            </Button>
          </GridItem>
          {swal}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default AddDepartment;
