export const campaignTargets = [
  {
    name: 'Discount Promotion',
  },
  {
    name: 'Brand Promotion',
  },
  {
    name: 'Company Promotion',
  },
  {
    name: 'Product Promotion',
  },
];

export const communicationChannels = [
  {
    name: 'Email',
  },
  {
    name: 'Phone Call',
  },
  {
    name: 'SMS',
  },
  {
    name: 'Design Material',
  },
  {
    name: 'Social Media Platforms',
  },
];

export const campaignForTypes = [
  {
    name: 'Individual',
  },
  {
    name: 'Corporate',
  },
];
