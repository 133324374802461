import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { layouts } from '../../variables/Permissions/layouts';
import { createPosition } from '../../_services/position';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {};
const useStyles = makeStyles(styles);

const PozisyonEkleDialog = (props) => {
  const { open, toggle, positions, setMessage } = props;
  const [layoutPermissions, setlayoutPermissions] = useState([]);
  const [viewPermissions, setViewPermissions] = useState([]);
  const [name, setName] = useState('');
  const [reportsTo, setReportsTo] = useState([]);
  const [alert, setAlert] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();

  const handleAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInnerChange = (event) => {
    const { name } = event.target;
    const index = viewPermissions.indexOf(name);
    let newInnerChecked = [];
    if (index > -1) {
      newInnerChecked = [
        ...viewPermissions.slice(0, index),
        ...viewPermissions.slice(index + 1),
      ];
    } else {
      newInnerChecked = [...viewPermissions, name];
    }
    setViewPermissions(newInnerChecked);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    let views = [];
    let layoutViews = [];
    const index = layoutPermissions.indexOf(name);
    let newChecked = [];
    layouts.map((l) => {
      if (l.url === name) {
        layoutViews = l.views.map((v) => {
          return v.url;
        });
      }
    });
    if (index > -1) {
      newChecked = [
        ...layoutPermissions.slice(0, index),
        ...layoutPermissions.slice(index + 1),
      ];
      views = viewPermissions.filter((v) => !layoutViews.includes(v));
    } else {
      newChecked = [...layoutPermissions, name];
      views = [...viewPermissions, ...layoutViews];
    }
    setlayoutPermissions(newChecked);
    setViewPermissions(views);
  };

  const handleSubmit = () => {
    if (name === '') {
      setAlert('Pozisyon adı boş bırakılamaz');
    } else {
      const data = {
        name,
        reportsTo,
        layoutPermissions,
        viewPermissions,
      };
      createPosition(data)
        .then((res) => {
          if (res.data && res.data.code && res.data.code === 200) {
            setMessage('İşlem Başarılı!!!!');
            toggle();
            setlayoutPermissions([]);
            setName('');
            setViewPermissions([]);
            setReportsTo([]);
          } else {
            setMessage(res.data.message);
          }
        })
        .catch((err) => setMessage('Bir hata oluştu!'));
    }
  };

  return (
    <Dialog open={open} onClose={toggle} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Pozisyon Ekle</DialogTitle>
      <DialogContent>
        {alert !== '' && <SnackbarContent message={alert} color='danger' />}
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText='Pozisyon Adı'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                  setAlert('');
                },
              }}
            ></CustomInput>
          </GridItem>
          <GridItem xs={12}>
            <FormControl fullWidth>
              <InputLabel id='yoneticiler'>Yöneticiler</InputLabel>
              <Select
                labelId='yoneticiler'
                id='yoneticiSelectBox'
                value={reportsTo}
                onChange={(e) => setReportsTo(e.target.value)}
              >
                {/*map ile positionlar dönülecek ve menu item olarak yazdırılacak */}
                {positions.map((position) => {
                  return (
                    <MenuItem value={position._id} key={position._id}>
                      {position.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <FormControl component='fieldset'>
                <FormGroup>
                  {layouts.map((layout) => {
                    return (
                      <GridItem xs={12} key={layout.url}>
                        <Accordion
                          expanded={layoutPermissions.includes(layout.url)}
                          onChange={handleAccordion(layout.url)}
                        >
                          <AccordionSummary
                            expandIcon={<></>}
                            aria-controls='panel4bh-content'
                            id='panel4bh-header'
                          >
                            <Typography className={classes.heading}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={layoutPermissions.includes(
                                      layout.url
                                    )}
                                    name={layout.url}
                                    onChange={handleChange}
                                  />
                                }
                                label={layout.name}
                              />
                            </Typography>
                            {/* { loading
                                  ? <CircularProgress color="secondary" />
                                  :   <Typography className={classes.secondaryHeading}>
                                          {addedNumberListLength} numara eklendi
                                      </Typography>
                              } */}
                          </AccordionSummary>
                          <AccordionDetails>
                            <GridContainer>
                              {layout.views.map((view) => {
                                return (
                                  <GridItem xs={12} key={view.url}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        position: 'relative',
                                        left: '15px',
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={viewPermissions.includes(
                                              view.url
                                            )}
                                            name={view.url}
                                            onChange={handleInnerChange}
                                          />
                                        }
                                        label={view.name}
                                      />
                                    </div>
                                  </GridItem>
                                );
                              })}
                            </GridContainer>
                          </AccordionDetails>
                        </Accordion>
                      </GridItem>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button size='lg' simple color='success' block onClick={handleSubmit}>
          Ekle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PozisyonEkleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default PozisyonEkleDialog;
