export const text = {
    title : {
        tr : 'Hammaddeler',
        en : 'Raw Materials'
    },
    addButton : {
        tr : "Hammadde Ekle",
        en : "Add Raw Material"
    },
    columns : {
        code : {
            tr : "Kodu",
            en : "Code"
        },
        name : {
            tr : "Adı",
            en : "Raw Name"
        },
        _type : {
            tr : "Tipi",
            en : "Raw Type"
        },
        rawUnit : {
            tr : "Birimi",
            en : "Unit"
        }
    }
}