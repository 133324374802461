import Calendar from 'views/Calendar/Calendar.js';

// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
import GridOn from '@material-ui/icons/GridOn';
import Image from '@material-ui/icons/Image';
import Place from '@material-ui/icons/Place';
import Timeline from '@material-ui/icons/Timeline';
import WidgetsIcon from '@material-ui/icons/Widgets';

var projectRoutes = [
  {
    path: '/anasayfa',
    name: 'Anasayfa',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/proje-yonetimi'
  },
  {
    collapse: true,
    name: 'Bileşenler',
    rtlName: 'لوحة القيادة',
    icon: DashboardIcon,
    component: Calendar,
    layout: '/proje-yonetimi',
    state: 'componentsCollapse',
    views: [
      {
        path: '/hammadde-tipleri',
        name: 'Hammadde Tipleri',
        mini: 'HT',
        component: Calendar,
        layout: '/proje-yonetimi'
      },
      {
        path: '/hammaddeler',
        name: 'Hammaddeler',
        mini: 'HM',
        component: Calendar,
        layout: '/proje-yonetimi'
      },
      {
        path: '/departmanlar',
        name: 'Departmanlar',
        mini: 'DP',
        component: Calendar,
        layout: '/proje-yonetimi'
      },
      {
        path: '/islemler',
        name: 'İşlemler',
        mini: 'IS',
        component: Calendar,
        layout: '/proje-yonetimi'
      }
    ]
  }
];
export default projectRoutes;
