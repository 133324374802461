import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import DataGrid from 'components/DataGrid/SimpleGrid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';

const HalfProducts = () => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <GridItem xs={12} sm={2}>
            <Link to={{ pathname: '/erp/addproduct', isFullProduct: false }}>
              <Button color='success'>
                <AddCircleOutlineIcon />
                Add Half Product
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default HalfProducts;
