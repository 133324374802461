import React, { useState, useEffect } from 'react';
import { createCampaignType } from '_services/campaignType';
import {
  campaignForTypes,
  communicationChannels,
  campaignTargets,
} from 'variables/CampaignTypes/CampaignTypes';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { successAlert, warningAlert } from 'components/SweetAlerts/Alerts.js';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Select } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);
const AddCampaignType = (props) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const { edit, infos } = props.location.state;
  const [user] = useAtom(userAtom);
  const [name, setName] = useState('');
  const [campaignFor, setCampaignFor] = useState('');
  const [communicationChannel, setCommunicationChannel] = useState([]);
  const [campaignTarget, setCampaignTarget] = useState('');
  const [code, setCode] = useState('');
  const [alert, setAlert] = useState(null);

  const handleChange = (e) => {
    setCommunicationChannel(e.target.value);
  };
  // channels, name, target, code
  useEffect(() => {
    if (edit) {
      setName(infos.name);
      setCommunicationChannel(infos.channels);
      setCampaignTarget(infos.target);
      setCode(infos.code);
      setCampaignFor(infos.isIndividual ? 'Individual' : 'Corporate');
    }
  }, []);

  const handleSubmit = () => {
    if (
      !name ||
      !campaignFor ||
      !communicationChannel ||
      !campaignTarget ||
      !code
    ) {
      warningAlert(setAlert, hideAlert, classes2, 'All fields are required!');
    } else {
      const data = {
        name: name,
        target: campaignTarget,
        code: code,
        channels: communicationChannel,
        isIndividual: campaignFor === 'Individual' ? true : false,
      };
      createCampaignType(data, user.user.token)
        .then((res) => {
          if (res.data && res.data.code && res.data.code === 200) {
            setName('');
            setCampaignTarget('');
            setCode('');
            setCommunicationChannel([]);
            setCampaignFor('');
            successAlert(setAlert, hideAlert, classes2, '');
          }
        })
        .catch((err) => {
          warningAlert(setAlert, hideAlert, classes2, 'Process Failed');
        });
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <h4 className={classes.cardTitle}>
            {edit ? 'Edit' : 'Add'} Campaign Type
          </h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Title'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Code'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: code,
                onChange: (e) => {
                  setCode(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <FormControl fullWidth classes={classes.selectFromControl}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                Campaign For
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={campaignFor}
                onChange={(e) => setCampaignFor(e.target.value)}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                {campaignForTypes.map((type) => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={type.name}
                      key={type.name}
                    >
                      {type.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={6}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id='demo-mutiple-chip-label'>
                Communication Channel
              </InputLabel>
              <Select
                labelId='demo-mutiple-chip-label'
                id='demo-mutiple-chip'
                multiple
                value={communicationChannel}
                onChange={handleChange}
                input={<Input id='select-multiple-chip' />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => {
                      return (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      );
                    })}
                  </div>
                )}
              >
                {communicationChannels.map((pos) => (
                  <MenuItem key={pos.name} value={pos.name}>
                    {pos.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={12} style={{ marginTop: '15px' }}>
            <FormControl fullWidth classes={classes.selectFromControl}>
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                Campaign Target
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={campaignTarget}
                onChange={(e) => setCampaignTarget(e.target.value)}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                {campaignTargets.map((type) => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={type.name}
                      key={type.name}
                    >
                      {type.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '15px' }}>
            <GridContainer justify='flex-end'>
              <GridItem xs={3}>
                <Button color='success' block onClick={handleSubmit}>
                  {edit ? 'Save' : 'Add'}
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
      {alert}
    </Card>
  );
};

export default AddCampaignType;
