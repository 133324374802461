import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDepartments } from '_services/department';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import DataGrid from 'components/DataGrid/SimpleGrid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import { text } from '../../language/erp/components/departments'

const Departments = () => {
  const [user] = useAtom(userAtom);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  const { language } = user.user

  useEffect(() => {
    if (user.user && user.user.token) {
      setLoading(true);
      getDepartments(user.user.token)
        .then((res) => {
          setLoading(false);
          if (res.data && res.data.code === 200) {
            setDepartments(res.data.data);
          }
        })
        .catch((err) => setLoading(false));
    }
  }, [user]);

  const columns = [
    { name: 'name', title: text.columns.name[language] },
    { name: 'description', title: text.columns.description[language] },
  ];

  const defaultColumnWidths = [
    { columnName: 'name', width: 400 },
    { columnName: 'description', width: 400 },
  ];

  const rows = () => {
    return departments.map((l) => ({
      name: l.name,
      description: l.description,
    }));
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <Link to='/erp/addDepartment'>
            <Button color='success'>
              <AddCircleOutlineIcon />
              {text.addButton[language]}
            </Button>
          </Link>
        </GridContainer>
        <GridItem xs={12}>
          {loading ? (
            <Paper
              style={{
                display: 'flex',
                height: 300,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color='secondary' />
            </Paper>
          ) : (
            <Card>
              <CardHeader color='rose' icon>
                <CardText color='rose'>
                  <h4>{text.title[language]}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <DataGrid
                  columns={columns}
                  rows={rows()}
                  defaultColumnWidths={defaultColumnWidths}
                  lang = {language}
                />
              </CardBody>
            </Card>
          )}
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default Departments;
