import axios from 'axios';
import { domain } from './domain';

export const createCompany = (data, token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-type': 'application/json',
    },
    data,
  };
  return axios(domain + '/company', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};

export const updateCompany = (data, token) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-type': 'application/json',
    },
    data,
  };
  return axios(domain + '/company', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getCompanies = (token) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-type': 'application/json',
    },
  };
  return axios(domain + '/getcompanies', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};
