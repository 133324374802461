import axios from 'axios';
import { domain } from './domain';

export const getpositions = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data
  };
  return axios(domain + '/getpositions', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};

export const createPosition = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data
  };
  return axios(domain + '/position', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getPositionHierarchy = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data
  };
  return axios(domain + '/positionhierarchy', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
}