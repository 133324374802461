import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createRaw } from '../../_services/raw';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import {
  successAlert,
  warningAlert,
} from '../../components/SweetAlerts/Alerts';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SweetAlert from 'react-bootstrap-sweetalert';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
const useStyles = makeStyles(styles);

const AddRawMaterial = (props) => {
  const history = useHistory();
  const [user] = useAtom(userAtom);

  const classes = useStyles();

  const [name, setName] = useState(''); // required
  const [_type, set_Type] = useState('');
  const [code, setCode] = useState(''); // required
  const [rawUnit, setRawUnit] = useState('');
  const [alert, setAlert] = useState('');
  const [swal, setSwal] = useState(null);

  const hideAlert = () => {
    setSwal('');
    setName('');
    set_Type('');
    setCode('');
    setRawUnit('');
  };

  const handleSubmit = () => {
    if (name === '') {
      setAlert('Name field is required');
    } else if (code === '') {
      setAlert('Code field is required');
    } else {
      setAlert('');
      const data = {
        name: name,
        _type: _type,
        code: code,
        rawUnit: rawUnit,
      };
      createRaw(data, user.user.token)
        .then((res) => {
          if (res && res.data && res.data.code === 200) {
            successAlert(
              setSwal,
              hideAlert,
              classes,
              'Department successfully added'
            );
          }
        })
        .catch((err) => {
          warningAlert(
            setSwal,
            hideAlert,
            classes,
            'Department adding process failed'
          );
        });
    }
  };

  const handleSimple = (event) => {
    setRawUnit(event.target.value);
  };

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              justIcon
              color='transparent'
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </Button>
            <h4 className={classes.cardTitle}>Add Raw Material</h4>
          </div>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer justify='flex-end'>
          {alert !== '' && (
            <GridItem xs={12}>
              <SnackbarContent message={alert} color='danger' />
            </GridItem>
          )}
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Type'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'type',
                value: _type,
                onChange: (e) => {
                  set_Type(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Code'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'code',
                value: code,
                onChange: (e) => {
                  setCode(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: '11px' }}
            >
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                Choose Unit
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={rawUnit}
                onChange={handleSimple}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='piece'
                >
                  Piece
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='size'
                >
                  Size
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='plate'
                >
                  Plate
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='gr'
                >
                  Gram
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='kg'
                >
                  Kilogram
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='cm'
                >
                  Centimeter
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='metre'
                >
                  Meter
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='mm'
                >
                  Milimeter
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='m2'
                >
                  Square Meter
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='m3'
                >
                  Cubic Meter
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='mt'
                >
                  Metretül
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='ml'
                >
                  Milliliter
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value='lt'
                >
                  Liter
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Button color='success' block onClick={handleSubmit}>
              Submıt
            </Button>
          </GridItem>
          {swal}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default AddRawMaterial;
