import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCompanies } from '_services/company';
import { updatePerson } from '_services/person';

import GridItem from 'components/Grid/GridItem.js';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button.js';
import EditIcon from '@material-ui/icons/Edit';
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const CompanyInfo = (props) => {
  const classes = useStyles();
  const classes2 = useStyles2();

  const {
    person,
    successAlert,
    warningAlert,
    setAlert,
    hideAlert,
    setPerson,
  } = props;

  const [editable, setEditable] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');

  useEffect(() => {
    if (person.company) {
      setCompany(person.company._id);
    }
    getCompanies().then((res) => {
      if (res.data && res.data.code && res.data.code === 200) {
        setCompanies(res.data.data);
      }
    });
  }, []);

  const handleChange = () => {
    const data = {
      _id: person._id,
      company: company,
    };
    updatePerson(data)
      .then((res) => {
        if (res.data.code && res.data.code === 200) {
          setEditable(!editable);
          setPerson(res.data.data);
          successAlert(setAlert, hideAlert, classes2, 'Person updated!');
        }
      })
      .catch((err) => {
        warningAlert(setAlert, hideAlert, classes2, 'Process failed.');
      });
  };

  return (
    <GridContainer>
      <GridItem xs={11}>
        <GridContainer justify='flex-end'>
          <GridItem xs={2}>
            <Button
              block
              justIcon
              simple
              size='lg'
              color='success'
              onClick={() => setEditable(!editable)}
            >
              <EditIcon />
            </Button>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} md={12}>
        <FormControl
          fullWidth
          classes={classes.selectFromControl}
          disabled={!editable}
        >
          <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
            Company
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            inputProps={{
              name: 'simpleSelect',
              id: 'simple-select',
            }}
          >
            {companies.map((comp) => {
              return (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={comp._id}
                  key={comp._id}
                >
                  {comp.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={11}>
        <GridContainer justify='flex-end'>
          <GridItem xs={2}>
            {editable && (
              <Button color='success' block onClick={handleChange}>
                Save
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

CompanyInfo.propTypes = {
  person: PropTypes.object.isRequired,
};

export default CompanyInfo;
