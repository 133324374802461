import React from 'react';

import AddFullProduct from './AddFullProduct';
import AddHalfProduct from './AddHalfProduct';

const AddProduct = (props) => {
  const { isFullProduct } = props.location;

  return isFullProduct ? <AddFullProduct /> : <AddHalfProduct />;
};

export default AddProduct;
