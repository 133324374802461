const sidebar = [
  // Admin
  {
    path: '/human-resources',
    en: 'HR',
    tr: 'İK',
  },
  {
    path: '/positions',
    en: 'Positions',
    tr: 'Pozisyonlar',
  },
  {
    path: '/people',
    en: 'People',
    tr: 'Kişiler',
  },
  {
    path: '/users',
    en: 'Users',
    tr: 'Kullanıcılar',
  },
  // Erp
  {
    path: '/components',
    en: 'Components',
    tr: 'Bileşenler',
  },
  {
    path: '/erpdashboard',
    en: 'Dashboard',
    tr: 'Anasayfa',
  },
  {
    path: '/raw-materials',
    en: 'Raw Materials',
    tr: 'Hammaddeler',
  },
  {
    path: '/departments',
    en: 'Departments',
    tr: 'Departmanlar',
  },
  {
    path: '/operations',
    en: 'Operations',
    tr: 'İşlemler',
  },
  {
    path: '/product',
    en: 'Products',
    tr: 'Ürünler',
  },
  {
    path: '/products',
    en: 'Products',
    tr: 'Ürünler',
  },
  {
    path: '/half-products',
    en: 'Half Products',
    tr: 'Yarımamüller',
  },
  {
    path: '/product-groups',
    en: 'Product Groups',
    tr: 'Ürün Grupları',
  },
  {
    path: '/product-trees',
    en: 'Product Trees',
    tr: 'Ürün Ağaçları',
  },
  {
    path: '/purchase',
    en: 'Purchansing',
    tr: 'Satın Alma',
  },
  {
    path: '/purchase-processes',
    en: 'Purchase Processes',
    tr: 'Satın Alma İşlemleri',
  },
  {
    path: '/purchasing',
    en: 'Purchasing',
    tr: 'Satın Alma',
  },
  {
    path: '/reports',
    en: 'Reports',
    tr: 'Raporlar',
  },
  {
    path: '/order',
    en: 'Orders',
    tr: 'Siparişler',
  },
  {
    path: '/order-processes',
    en: 'Order Processes',
    tr: 'İşlemler',
  },
  {
    path: '/orders',
    en: 'Orders',
    tr: 'Siparişler',
  },
  {
    path: '/order-reports',
    en: 'Order Reports',
    tr: 'Sipariş Raporları',
  },
  {
    path: '/production',
    en: 'Production',
    tr: 'Üretim',
  },
  {
    path: '/production-orders',
    en: 'Production Orders',
    tr: 'İş Emirleri',
  },
  {
    path: '/produciton-order-archive',
    en: 'Production Order Archive',
    tr: 'İş Emri Arşivi',
  },
  {
    path: '/plan-production',
    en: 'Plan Production',
    tr: 'Üretim Planla',
  },
  {
    path: '/planned-productions',
    en: 'Planned Productions',
    tr: 'Planlanmış Üretimler',
  },
  {
    path: '/production-reports',
    en: 'Production Reports',
    tr: 'Üretim Raporları',
  },
  {
    path: '/production-archive',
    en: 'Production Archive',
    tr: 'Üretim Arşivi',
  },
  {
    path: '/stock',
    en: 'Stock',
    tr: 'Stok',
  },
  {
    path: '/waybills',
    en: 'Waybills',
    tr: 'İrsaliyeler',
  },
  {
    path: '/waybills-archive',
    en: 'Waybills Archive',
    tr: 'İrsaliye Arşivi',
  },
  {
    path: '/raw-materials-shipment',
    en: 'Raw Materials Shipment',
    tr: 'Hammadde Sevkiyatı',
  },
  {
    path: '/raw-material-stock',
    en: 'Raw Material Stock',
    tr: 'Hammadde Stok',
  },
  {
    path: '/product-stock',
    en: 'Product Stock',
    tr: 'Ürün Stok',
  },
  {
    path: '/half-product-stock',
    en: 'Half Product Stock',
    tr: 'Yarımamül Stok',
  },
  //crm routes
  {
    path: '/company',
    en: 'Companies',
    tr: 'Şirketler',
  },
  {
    path: '/companies',
    en: 'Companies',
    tr: 'Şirketler',
  },
  {
    path: '/marketing-strategies',
    en: 'Marketing Strategies',
    tr: 'Pazarlama Stratejileri',
  },
  {
    path: '/campaigns',
    en: 'Campaigns',
    tr: 'Kampanyalar',
  },
  {
    path: '/campaign-types',
    en: 'Campaign Types',
    tr: 'Kampanya Tipleri',
  },
  {
    path: '/campaign-archive',
    en: 'Campaign Archive',
    tr: 'Kampanya Arşivi',
  },
  {
    path: '/campaign-analytics',
    en: 'Campaign Analytics',
    tr: 'Kampanya Analizleri',
  },
  {
    path: '/company-based-marketing',
    en: 'Company Based Marketing',
    tr: 'Şirket Odaklı Pazarlama',
  },
  {
    path: '/marketing-tools',
    en: 'Marketing Tools',
    tr: 'Pazarlama Araçları',
  },
  {
    path: '/company-marketing-tools',
    en: 'Company Marketing Tools',
    tr: 'Şirket Pazarlama Araçları',
  },
  {
    path: '/company-based-strategies',
    en: 'Company Based Strategies',
    tr: 'Şirket Odaklı Stratejiler',
  },
  {
    path: '/company-based-reports',
    en: 'Reports',
    tr: 'Raporlar',
  },
  {
    path: '/sales',
    en: 'Sales',
    tr: 'Satış',
  },
  {
    path: '/service',
    en: 'Service',
    tr: 'Servis',
  },
  {
    path: '/sales',
    en: 'Sales',
    tr: 'Satış',
  },
  {
    path: '/potential-customers',
    en: 'Potential Customers',
    tr: 'Potansiyel Müşteriler',
  },
  {
    path: '/potential-sales',
    en: 'Potential Sales',
    tr: 'Potansiyel Satışlar',
  },
  {
    path: '/organizations-with-relations',
    en: 'Organizations WR',
    tr: 'Tüm Müşteriler',
  },
  {
    path: '/customers',
    en: 'Customers',
    tr: 'Aktif Müşteriler',
  },
  {
    path: '/competitors',
    en: 'Competitors',
    tr: 'Rakipler',
  },
  {
    path: '/products',
    en: 'Products',
    tr: 'Ürünler',
  },
  {
    path: '/all-products',
    en: 'All Producs',
    tr: 'Tüm Ürünler',
  },
  {
    path: '/product-archive',
    en: 'Product Archive',
    tr: 'Ürün Arşivi',
  },
  {
    path: '/products-to-customer',
    en: 'P2C',
    tr: 'Müşteri Ürünleri',
  },
  {
    path: '/offers',
    en: 'Offers',
    tr: 'Teklifler',
  },
  {
    path: '/orders',
    en: 'Orders',
    tr: 'Siparişler',
  },
  {
    path: '/organization-orders',
    en: 'Organization Orders',
    tr: 'Firma Bazlı',
  },
  {
    path: '/product-orders',
    en: 'Product Orders',
    tr: 'Ürün Bazlı',
  },
  {
    path: '/invoices',
    en: 'Invoices',
    tr: 'Faturalar',
  },
  {
    path: '/reports',
    en: 'Order Reports',
    tr: 'Sipariş Raporları',
  },
  {
    path: '/services',
    en: 'Services',
    tr: 'Servisler',
  },
  {
    path: '/incidents',
    en: 'Incidents',
    tr: 'Aksaklıklar',
  },
  {
    path: '/customer-requests',
    en: 'Customer Requests',
    tr: 'Müşteri Talepleri ',
  },
  {
    path: '/faq',
    en: 'FAQ',
    tr: 'SSS',
  },
  {
    path: '/solutions',
    en: 'Solutions',
    tr: 'Çözümler',
  },
  {
    path: '/contrats',
    en: 'Contrats',
    tr: 'Kontratlar',
  },
];

export const sidebarLP = (ln) => {
  return sidebar.map((s) => {
    return { path: s.path, name: s[ln] };
  });
};
