import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../_services/company';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import DataGrid from 'components/DataGrid/SimpleGrid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';

const Companies = () => {
  const [user] = useAtom(userAtom);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (user.user && user.user.token) {
      setLoading(true);
      getCompanies(user.user.token)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          if (res.data && res.data.code === 200) {
            setCompanies(res.data.data);
          }
        })
        .catch((err) => setLoading(false));
    }
  }, [user]);

  const columns = [
    { name: 'code', title: 'Code' },
    { name: 'name', title: 'Name' },
    { name: 'address', title: 'Address' },
    { name: 'website', title: 'Website' },
    { name: 'action', title: 'Action' },
  ];

  const defaultColumnWidths = [
    { columnName: 'code', width: 100 },
    { columnName: 'name', width: 600 },
    { columnName: 'address', width: 400 },
    { columnName: 'website', width: 200 },
    { columnName: 'action', width: 100 },
  ];

  const rows = () => {
    return companies.map((l) => ({
      code: l.code,
      name: l.name,
      address: l.address,
      website: l.website,
      action: (
        <Tooltip title='Details'>
          <IconButton color='secondary' onClick={() => handleCompanyDetails(l)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    }));
  };

  const handleCompanyDetails = (company) => {
    history.push('/crm/company-detail', { company });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <Link to='/crm/add-company'>
            <Button color='success'>
              <AddCircleOutlineIcon />
              Add Company
            </Button>
          </Link>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        {loading ? (
          <Paper
            style={{
              display: 'flex',
              height: 300,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color='secondary' />
          </Paper>
        ) : (
          <Card>
            <CardHeader color='rose' icon>
              <CardText color='rose'>
                <h4>Companies</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <DataGrid
                columns={columns}
                rows={rows()}
                defaultColumnWidths={defaultColumnWidths}
              />
            </CardBody>
          </Card>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default Companies;
