import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  ColumnChooser,
  TableColumnVisibility,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';

import { groupingPanelMessages, pagingPanelMessages, tableMessages } from '../../language/simpleGrid.js'

const DataGrid = (props) => {
  const { columns, rows, defaultColumnWidths, lang } = props


  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([5, 10, 15, 20, 30, 50]);
  const [grouping] = useState([]);
  const [groupingStateColumnExtensions] = useState([]);
  const [defaultHiddenColumnNames] = useState([]);
  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

  return (
      <Grid
        rows={rows}
        columns={columns}
      >
        <SortingState
          defaultSorting={[]}
        />
        <DragDropProvider />
        <GroupingState
          defaultGrouping={grouping}
          columnExtensions={groupingStateColumnExtensions}
        />
        <IntegratedSorting />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          messages = {pagingPanelMessages[lang]}
        />
        <IntegratedPaging />
        <IntegratedGrouping />
        <Table 
          messages={tableMessages[lang]}
        />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
        />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <TableGroupRow />
        <Toolbar />
        <GroupingPanel 
          showGroupingControls 
          messages={groupingPanelMessages[lang]}  
        />
        <ColumnChooser />
        <PagingPanel 
          pageSizes={pageSizes}
          messages={pagingPanelMessages[lang]}
        />
      </Grid>
  );
}

export default DataGrid