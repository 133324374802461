import axios from 'axios';
import { domain } from './domain';

export const createPerson = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data,
  };
  return axios(domain + '/person', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};

export const getPerson = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json' },
    data,
  };
  return axios(domain + '/getpeople', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};

export const updatePerson = (data) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-type': 'application/json' },
    data,
  };
  return axios(domain + '/person', requestOptions)
    .then((response) => {
      if (response.data.code) {
        return response;
      } else {
        throw new Error('Bağlantı hatası!');
      }
    })
    .catch((error) => {
      return error;
    });
};
