import React, { useEffect, useState } from 'react';
import { getRaws } from '../../_services/raw.js';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import DataGrid from 'components/DataGrid/SimpleGrid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import { text } from '../../language/erp/components/rawMaterials'


const RawMaterials = (props) => {
  const [user] = useAtom(userAtom);
  const [raws, setRaws] = useState([]);
  const [loading, setLoading] = useState(false);

  const { language } = user.user

  useEffect(() => {
    if (user.user && user.user.token) {
      setLoading(true);
      getRaws(user.user.token)
        .then((res) => {
          setLoading(false);
          if (res.data && res.data.code === 200) {
            setRaws(res.data.data);
          }
        })
        .catch((err) => setLoading(false));
    }
  }, [user]);

  const columns = [
    { name: 'code', title: text.columns.code[language] },
    { name: 'name', title: text.columns.name[language] },
    { name: '_type', title: text.columns._type[language] },
    { name: 'rawUnit', title: text.columns.rawUnit[language] },
  ];

  const defaultColumnWidths = [
    { columnName: 'code', width: 250 },
    { columnName: 'name', width: 600 },
    { columnName: '_type', width: 250 },
    { columnName: 'rawUnit', width: 200 },
  ];

  const rows = () => {
    return raws.map((l) => ({
      code: l.code,
      name: l.name,
      _type: l._type,
      rawUnit: l.rawUnit,
    }));
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <GridItem xs={12} sm={2}>
            <Link to='/erp/addrawmaterial'>
              <Button color='success'>
                <AddCircleOutlineIcon />
                {text.addButton[language]}
              </Button>
            </Link>
          </GridItem>
          <GridItem xs={12}>
            {loading ? (
              <Paper
                style={{
                  display: 'flex',
                  height: 300,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress color='secondary' />
              </Paper>
            ) : (
              <Card>
                <CardHeader color='rose' icon>
                  <CardText color='rose'>
                    <h4>{text.title[language]}</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <DataGrid
                    columns={columns}
                    rows={rows()}
                    defaultColumnWidths={defaultColumnWidths}
                    lang = {language}
                  />
                </CardBody>
              </Card>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default RawMaterials;
