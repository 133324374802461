import Dashboard2 from 'views/Dashboard/Dashboard2.js';
import Schedule from 'views/Schedular/Schedular.js';
import Companies from 'views/Company/Companies.js';
import AddCompanies from 'views/Company/AddCompany.js'
import ProductGroups from 'views/Products/ProductGroups.js'
import CompanyDetails from 'views/Company/CompanyDetails.js';
import Products from 'views/Products/Crm/Products.js';
import AddProduct from 'views/Products/Crm/AddProduct.js';
import CampaignTypes from 'views/Campaign/CampaignTypes';
import AddCampaignType from 'views/Campaign/AddCampaignType';

// @material-ui/icons
import Apps from '@material-ui/icons/Apps';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRange from '@material-ui/icons/DateRange';
import GridOn from '@material-ui/icons/GridOn';
import Image from '@material-ui/icons/Image';
import Place from '@material-ui/icons/Place';
import Timeline from '@material-ui/icons/Timeline';
import WidgetsIcon from '@material-ui/icons/Widgets';

var crmRoutes = [
  {
    path: '/crm-dashboard',
    icon: DashboardIcon,
    component: Dashboard2,
    layout: '/crm',
  },
  {
    path: '/crm-dashboard2',
    icon: DashboardIcon,
    component: Schedule,
    layout: '/crm',
  },
  {
    path: '/add-company',
    icon: DashboardIcon,
    component: AddCompanies,
    layout: '/crm',
    invisible: true,
  },
  {
    path: "/product-groups",
    icon: DashboardIcon,
    component: ProductGroups,
    layout: "/crm",
    invisible: true,
  },
  {
    path: "/add-product",
    path: '/add-product',
    icon: DashboardIcon,
    component: AddProduct,
    layout: '/crm',
    invisible: true,
  },
  {
    path: '/company-detail',
    icon: DashboardIcon,
    component: CompanyDetails,
    layout: '/crm',
    invisible: true,
  },
  {
    path: '/add-campaign-type',
    icon: DashboardIcon,
    component: AddCampaignType,
    layout: '/crm',
    invisible: true,
  },

  {
    collapse: true,
    icon: DashboardIcon,
    component: Companies,
    path: '/company',
    layout: '/crm',
    state: 'componentsCollapse',
    views: [
      {
        path: '/companies',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/company-based-marketing',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/company-marketing-tools',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/company-based-strategies',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/company-based-reports',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
    ],
  },
  {
    collapse: true,
    icon: DashboardIcon,
    component: Companies,
    path: '/products',
    layout: '/crm',
    state: 'productsCollapse',
    views: [
      {
        path: '/all-products',
        mini: 'PC',
        component: Products,
        layout: '/crm',
      },
      {
        path: '/product-archive',
        mini: 'PS',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/products-to-customer',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/offers',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
    ],
  },
  {
    path: '/marketing-strategies',
    icon: DashboardIcon,
    component: CompanyDetails,
    layout: '/crm',
    invisible: true,
  },
  {
    collapse: true,
    icon: DashboardIcon,
    component: Companies,
    path: '/campaigns',
    layout: '/crm',
    state: 'campaignCollapse',
    views: [
      {
        path: '/campaigns',
        mini: 'CT',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/campaign-types',
        mini: 'CT',
        component: CampaignTypes,
        layout: '/crm',
      },
      {
        path: '/campaign-archive',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/campaign-analytics',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
    ],
  },
  {
    collapse: true,
    icon: DashboardIcon,
    component: Companies,
    path: '/sales',
    layout: '/crm',
    state: 'salesCollapse',
    views: [
      {
        path: '/potential-customers',
        mini: 'PC',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/potential-sales',
        mini: 'PS',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/organizations-with-relations',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/customers',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/competitors',
        mini: 'CM',
        component: Companies,
        layout: '/crm',
      },
    ],
  },
  {
    collapse: true,
    icon: DashboardIcon,
    component: Companies,
    path: '/orders',
    layout: '/crm',
    state: 'ordersCollapse',
    views: [
      {
        path: '/organization-orders',
        mini: 'OO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/product-orders',
        mini: 'PO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/invoices',
        mini: 'PO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/reports',
        mini: 'PO',
        component: Companies,
        layout: '/crm',
      },
    ],
  },
  {
    collapse: true,
    icon: DashboardIcon,
    component: Companies,
    path: '/services',
    layout: '/crm',
    state: 'servicesCollapse',
    views: [
      {
        path: '/incidents',
        mini: 'OO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/customer-requests',
        mini: 'PO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/faq',
        mini: 'PO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/solutions',
        mini: 'SO',
        component: Companies,
        layout: '/crm',
      },
      {
        path: '/contrats',
        mini: 'PO',
        component: Companies,
        layout: '/crm',
      },
    ],
  },
];
export default crmRoutes;
