import React, { useState } from 'react';
import { addProductGroup } from '_services/products'
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from "components/CustomButtons/Button.js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CustomInput from 'components/CustomInput/CustomInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { unitsLP } from 'variables/units.js'
import { warningAlert } from 'components/SweetAlerts/Alerts.js'


const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddProductGroupDialog = (props) => {
  const [user] = useAtom(userAtom);
  const [ name, setName ] = useState('')
  const [ shortName, setShortName ] = useState('')
  const [ fieldName, setFieldName ] = useState('')
  const [ fieldUnit, setFieldUnit ] = useState('')
  const [ isNumber, setIsNumber ] = useState(true)
  const [ dynamicFields, setDynamicFields ] = useState([])
  const [ alert, setAlert ] = useState(null)

  const { language } = user.user
  const { open, toggle, token, fetchData } = props
  const classes = useStyles();
  const classes2 = useStyles2();

  const hideAlert = () => setAlert(null)

  const handleAdd = () => {
    if( fieldName === '' || fieldUnit === ''){
      warningAlert(setAlert, hideAlert, classes2 ,'Field Name and unit required.')
    }else{
      const field = { name : fieldName, unit : fieldUnit, isNumber, }
      setDynamicFields([...dynamicFields, field])
      setFieldName('')
      setFieldUnit('')
      setIsNumber(true)
    }
  }

  const handleRemove = index => {
    setDynamicFields([
      ...dynamicFields.slice(0, index), 
      ...dynamicFields.slice(index+1)
    ])
  }

  const handleSubmit = () => {
    if( name === '' || shortName === ''){
      warningAlert(setAlert, hideAlert, classes2 ,'Name and Shortname required.')
    }else{
      const data = {
        name,
        shortName,
        dynamicFields
      }
      addProductGroup(token,data)
      .then( res => {
        if(res.data && res.data.code === 200){
          toggle()
          fetchData()
        }else{
          warningAlert(setAlert, hideAlert, classes2 ,'Operation failed. Please try again later')
        }
      })
      .catch( err => {
        warningAlert(setAlert, hideAlert, classes2 ,'Operation failed. Please try again later')
      })
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggle}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Add Product Group
        </DialogTitle>
        <DialogContent>
          <GridContainer justify='center'>
            <GridItem xs={12}>
              <CustomInput
                labelText='Group Name'
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    name: 'name',
                    value: name,
                    onChange: (e) => {
                    setName(e.target.value);
                    },
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText='Group Short Name'
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps={{
                    name: 'name',
                    value: shortName,
                    onChange: (e) => {
                    setShortName(e.target.value);
                    },
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <h6>Dynamic Fields</h6>
            </GridItem>
            <GridItem xs={12}>
              <GridContainer alignItems='baseline' justify = 'center'>
                <GridItem xs={4}>
                    <CustomInput
                      labelText='Field Name'
                      formControlProps={{
                          fullWidth: true,
                      }}
                      inputProps={{
                          name: 'name',
                          value: fieldName,
                          onChange: (e) => {
                          setFieldName(e.target.value);
                          },
                      }}
                    />
                </GridItem>
                <GridItem xs={4}>
                  <Select
                    fullWidth
                    value={fieldUnit}
                    onChange={(e) => setFieldUnit(e.target.value)}
                    MenuProps={{
                      className: classes.selectMenu,
                      }}
                      classes={{
                      select: classes.select,
                      }}

                      inputProps={{
                    }}
                  >
                      <MenuItem
                      classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                      }}
                      value={''}
                      >
                        Select..
                    </MenuItem> 
                    {unitsLP(language).map( unit => {
                      return(
                        <MenuItem
                          classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                          }}
                          value={unit.value}
                          key = {unit.value}
                          >
                            {unit.name}
                        </MenuItem> 
                      )
                    })}
                  </Select>
                </GridItem>
                <GridItem xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={isNumber}
                        onClick={() => setIsNumber(!isNumber)}
                        checkedIcon={
                          <Check className={classes.checkedIcon} />
                        }
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Is Number"
                  />
                </GridItem>
                <GridItem xs={2}>
                  <IconButton color="secondary" aria-label="add to shopping cart" onClick={handleAdd}>
                    <AddIcon />
                  </IconButton>
                </GridItem>
              </GridContainer>
            </GridItem>
            { dynamicFields.map( (f, i )=> {
              return(
                <GridItem xs={12} key={f.name + i}>
                  <GridContainer justify='center' alignItems='center'>
                    <GridItem xs={5}>
                      {f.name}
                    </GridItem>
                    <GridItem xs={5}>
                      {f.unit}
                    </GridItem>
                    <GridItem xs={2}>
                      <IconButton color="secondary" aria-label="add to shopping cart" onClick={() => handleRemove(i)}>
                        <RemoveIcon />
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              )
            })}
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button simple onClick={toggle} block color="danger">
            Cancel
          </Button>
          <Button simple onClick={handleSubmit} block color="success">
            Add Group
          </Button>
        </DialogActions>
      </Dialog>
      {alert}
    </div>
  );
}

export default AddProductGroupDialog