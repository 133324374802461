export const layouts = [
  {
    name: 'Üretim Yönetimi',
    url: 'uretim-yonetimi',
    views: [
      {
        name: 'Bileşenler',
        url: 'bilesenler'
      },
      {
        name: 'Ürünler',
        url: 'urunler'
      },
      {
        name: 'Satın Alma',
        url: 'satin-alma'
      },
      {
        name: 'Siparişler',
        url: 'siparisler'
      },
      {
        name: 'Üretim',
        url: 'uretim'
      },
      {
        name: 'Stok',
        url: 'stok'
      }
    ]
  },
  {
    name: 'CRM',
    url: 'crm',
        views: [
      {
        name: 'CRM1',
        url: 'crm1'
      },
      {
        name: 'CRM2',
        url: 'crm2'
      },
    ]
  },
  {
    name: 'Muhasebe',
    url: 'muhasebe',
        views: [
      {
        name: 'M1',
        url: 'm1'
      },
      {
        name: 'M2',
        url: 'm2'
      },
    ]
  },
  {
    name: 'Proje Yönetimi',
    url: 'proje-yonetimi',
    views: [
      {
        name: 'P1',
        url: 'p1'
      },
      {
        name: 'P2',
        url: 'p2'
      },

    ]
  },
  {
    name: 'Maliyet Analizi',
    url: 'maliyet-analizi',
        views: [
      {
        name: 'Ma1',
        url: 'ma1'
      },
      {
        name: 'Ma2',
        url: 'ma2'
      },

    ]
  }
];
