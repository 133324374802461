import React, { useState } from 'react';
import { createCompany } from '../../_services/company';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import SweetAlert from 'react-bootstrap-sweetalert';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { successAlert, warningAlert } from 'components/SweetAlerts/Alerts.js';
const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const AddCompany = () => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const [user] = useAtom(userAtom);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(''); // required
  const [code, setCode] = useState(''); // required
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [vergiDairesi, setVergiDairesi] = useState('');
  const [vkn, setVkn] = useState('');
  const [ticSicil, setTicSicil] = useState('');
  const [nickName, setNickName] = useState('');

  const handleSubmit = () => {
    const data = {
      name,
      code,
      address,
      website,
      email,
      phone,
      fax,
      vergiDairesi,
      vkn,
      ticSicil,
      nickName,
    };
    if (name === '') {
      warningAlert(setAlert, hideAlert, classes2, 'Company name is required!');
    } else if (code === '') {
      warningAlert(setAlert, hideAlert, classes2, 'Company code is required!');
    } else {
      setLoading(true);
      createCompany(data, user.user.token)
        .then((res) => {
          setLoading(false);
          if (res.data && res.data.code === 200) {
            setName('');
            setCode('');
            setAddress('');
            setWebsite('');
            setEmail('');
            setPhone('');
            setFax('');
            setVergiDairesi('');
            setVkn('');
            setTicSicil('');
            setNickName('');
            successAlert(setAlert, hideAlert, classes2, '');
          } else {
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <h4 className={classes.cardTitle}>Add Company</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer justify='flex-end'>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Title'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Company Short Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: nickName,
                onChange: (e) => {
                  setNickName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Code'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: code,
                onChange: (e) => {
                  setCode(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Email'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: email,
                onChange: (e) => {
                  setEmail(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Web Page'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: website,
                onChange: (e) => {
                  setWebsite(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Phone'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: phone,
                onChange: (e) => {
                  setPhone(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Fax'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: fax,
                onChange: (e) => {
                  setFax(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Tax Office'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: vergiDairesi,
                onChange: (e) => {
                  setVergiDairesi(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Tax ID'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: vkn,
                onChange: (e) => {
                  setVkn(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Trade Registration Number'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: ticSicil,
                onChange: (e) => {
                  setTicSicil(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={12}>
            <CustomInput
              labelText='Address'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: address,
                onChange: (e) => {
                  setAddress(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            {loading ? (
              <CircularProgress color='secondary' />
            ) : (
              <Button color='success' block onClick={handleSubmit}>
                Add
              </Button>
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
      {alert}
    </Card>
  );
};

export default AddCompany;
