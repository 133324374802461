import React, { useState, useEffect } from 'react';
import AddPersonDialog from 'components/Yetkiler/AddPersonDialog';
import Person from 'components/Person/Person';
import { getPerson } from '_services/person';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/CustomButtons/Button.js';
import SweetAlert from 'react-bootstrap-sweetalert';
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const Kisiler = () => {
  const classes = useStyles();

  const [showAddPerson, setShowAddPerson] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [swalAlert, setSwalAlert] = useState(null);
  const [persons, setPersons] = useState([]);

  const handleShowAddPerson = () => {
    setShowAddPerson(!showAddPerson);
  };

  useEffect(() => {
    if (alertMessage !== '') {
      setSwalAlert(
        <SweetAlert
          style={{ display: 'block', marginTop: '-100px' }}
          title={alertMessage}
          onConfirm={() => {
            setSwalAlert(null);
            setAlertMessage('');
          }}
          onCancel={() => {
            setSwalAlert(null);
            setAlertMessage('');
          }}
          confirmBtnCssClass={classes.button + ' ' + classes.info}
        ></SweetAlert>
      );
    }
  }, [alertMessage]);

  useEffect(() => {
    getPerson({})
      .then((res) => {
        if (res.data && res.data.code && res.data.code === 200) {
          setPersons(res.data.data);
        }
      })
      .catch((err) => null);
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer justify='flex-end'>
          <Button color='success' onClick={handleShowAddPerson}>
            <AddCircleOutlineIcon />
            Kişi Ekle
          </Button>
        </GridContainer>
      </GridItem>
      <GridItem xs={12}>
        <AddPersonDialog
          open={showAddPerson}
          toggle={handleShowAddPerson}
          setAlertMessage={setAlertMessage}
        />
      </GridItem>
      <GridContainer>
        {persons !== [] &&
          persons.map((person) => {
            return <Person person={person} key={person._id} />;
          })}
      </GridContainer>
      {swalAlert}
    </GridContainer>
  );
};

export default Kisiler;
