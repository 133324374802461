import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { userAtom } from '../../_atoms/user';
import { getDepartments } from '_services/department';
import { createOperation } from '_services/operations';
import {
  successAlert,
  warningAlert,
} from '../../components/SweetAlerts/Alerts';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { makeStyles } from '@material-ui/core/styles';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardText from 'components/Card/CardText.js';
import CardBody from 'components/Card/CardBody.js';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button.js';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
const useStyles = makeStyles(styles);

const AddOperation = () => {
  const classes = useStyles();
  const history = useHistory();
  const [user] = useAtom(userAtom);

  const [name, setName] = useState(''); // required
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [departments, setDepartments] = useState([]);
  const [units, setUnits] = useState(0);
  const [minutesPerUnit, setMinutesPerUnit] = useState(0);
  const [code, setCode] = useState(''); // required
  const [swal, setSwal] = useState(null);
  const [alert, setAlert] = useState('');

  const hideAlert = () => {
    setSwal(null);
    setName('');
    setDescription('');
    setDepartment('');
    setUnits(0);
    setMinutesPerUnit(0);
    setCode(0);
  };

  useEffect(() => {
    if (user.user && user.user.token) {
      getDepartments(user.user.token)
        .then((res) => {
          if (res.data && res.data.code === 200) {
            setDepartments(res.data.data);
          }
        })
        .catch((err) => null);
    }
  }, [user]);

  const handleSubmit = () => {
    if (name === '') {
      setAlert('Name field is required');
    } else if (code === '') {
      setAlert('Code field is required');
    } else {
      setAlert('');
      const data = {
        name: name,
        description: description,
        department: department,
        units: units,
        minutesPerUnit: minutesPerUnit,
        code: code,
      };
      createOperation(data, user.user.token)
        .then((res) => {
          if (res && res.data && res.data.code === 200) {
            successAlert(
              setSwal,
              hideAlert,
              classes,
              'Operation successfully added'
            );
          }
        })
        .catch((err) => {
          warningAlert(
            setSwal,
            hideAlert,
            classes,
            'Operation adding process failed'
          );
        });
    }
  };

  const handleDepChange = (e) => {
    setDepartment(e.target.value);
  };

  return (
    <Card>
      <CardHeader color='rose' icon>
        <CardText color='rose'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              justIcon
              color='transparent'
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon />
            </Button>
            <h4 className={classes.cardTitle}>Add Operation</h4>
          </div>
        </CardText>
      </CardHeader>
      <CardBody>
        <GridContainer justify='flex-end'>
          {alert !== '' && (
            <GridItem xs={12}>
              <SnackbarContent message={alert} color='danger' />
            </GridItem>
          )}
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: name,
                onChange: (e) => {
                  setName(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
              style={{ marginTop: '11px' }}
            >
              <InputLabel
                htmlFor='simple-select'
                className={classes.selectLabel}
              >
                Choose Department
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={department}
                onChange={handleDepChange}
                inputProps={{
                  name: 'simpleSelect',
                  id: 'simple-select',
                }}
              >
                {departments.map((dep) => {
                  return (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={dep._id}
                      key={dep.name}
                    >
                      {dep.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Unit'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name',
                value: units,
                onChange: (e) => {
                  setUnits(e.target.value);
                },
                type: 'number',
              }}
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <CustomInput
              labelText='Minutes Per Unit'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'minutesPerUnit',
                value: minutesPerUnit,
                onChange: (e) => {
                  setMinutesPerUnit(e.target.value);
                },
                type: 'number',
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText='Code'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'code',
                value: code,
                onChange: (e) => {
                  setCode(e.target.value);
                },
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText='Description'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'description',
                value: description,
                onChange: (e) => {
                  setDescription(e.target.value);
                },
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Button color='success' block onClick={handleSubmit}>
              Submıt
            </Button>
          </GridItem>
          {swal}
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default AddOperation;
